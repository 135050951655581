const initialState = {
  userInfo: { isAuthenticated: false },
  isOnline: false,
  installed:false,
  updateAvailable: false,
  migrationAvailable: false,
  migrationChecked: false
};
try {
  const userInfo = JSON.parse(localStorage.getItem('icat-user'));
  if (userInfo) {
    initialState.userInfo = userInfo;
  }
} catch (e) {
  //
}
  
  export default function appStateReducer(state = initialState, action) {
    switch (action.type) {
      case 'app/setUserInfo': {
        return {
          // Again, one less level of nesting to copy
          ...state,
          ...{userInfo: {...state.userInfo, ...action.payload } }
        }
      }
      case 'app/logout': {
        return {
          ...state,
          userInfo: {}
        }
      }
      case "app/setIsOnline": {
        return {
          ...state,
          isOnline: action.payload
        }
      }
      case "app/installed": {
        return {
          ...state,
          installed: action.payload
        }
      }
      case "app/setUpdateAvailable": {
        return {
          ...state,
          updateAvailable: action.payload
        }
      }
      case "app/migrationAvailable": {
        return {
          ...state,
          migrationAvailable: action.payload
        }
      }
      case "app/setMigrationChecked": {
        return {
          ...state,
          migrationChecked: true
        }
      }
      default:
        return state
    }
  }