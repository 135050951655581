import Dexie from 'dexie'
import getStorage from './index'

const legacyDbName = 'mainICATDatabase'

export default class WebStorageLegacyV2 {
  constructor () {
    this.storage = getStorage()
    if (this.storage.getDb) {
      this.db = this.storage.getDb()
    }
  }

  open () {
    this.legacyDb = new Dexie(legacyDbName)

    this.legacyDb.version(1).upgrade().stores({
      audits: '++localId,Id,ToolkitInstanceId,Status',
      toolkits: '++localId,Id,ToolkitId',
      facilities: '++localId,&Id',
      locations: '++localId,&Id',
      locationAgreements: '++localId,&Id',
      answers:
        '++localId,ToolkitInstanceId,[AuditId+QuestionId],QuestionId,SectionId,AuditId,[AuditId+SectionId],Id'
    })
    this.legacyDb.open()
  }

  async delete () {
    try {
      await this.legacyDb.delete()
      return true
    } catch {
      return false
    }
  }

  getSections (toolkit) {
    return toolkit.Sections.map((section, idx) => ({
      Index: idx,
      QuestionIds: toolkit.Questions.filter(p=>p.SectionId===section.Id).map(p=>p.Id),
      SectionId: section.Id,
      ToolkitInstanceId: toolkit.Id
    }))
  }

  async importToolkit (toolkit) {
    const toolkitModel = {
      Active: toolkit.Active,
      Closed: toolkit.Closed,
      CreatedBy: toolkit.CreatedBy,
      CreatedTs: toolkit.CreatedTs,
      Facts: toolkit.Facts,
      Id: toolkit.Id,
      InstanceName: toolkit.InstanceName,
      IsIdEmpty: toolkit.IsIdEmpty,
      Name: toolkit.Name,
      Notes: toolkit.Notes,
      OrganisationId: toolkit.OrganisationId,
      QuestionIds: toolkit.QuestionIds,
      Questions: toolkit.Questions,
      RequiresApproval: toolkit.RequiresApproval,
      SectionIds: toolkit.SectionIds,
      Sections: toolkit.Sections,
      Started: toolkit.Started,
      Status: toolkit.Status,
      ToolkitId: toolkit.ToolkitId,
      UpdatedTs: toolkit.UpdatedTs
    }

    const toolkitSummaryModel = {
      Id: toolkit.Id,
      InstanceName: toolkit.InstanceName,
      QuestionCount: toolkit.QuestionIds?.length,
      ToolkitId: toolkit.ToolkitId,
      ToolkitType: toolkit.ToolkitType || "Audit"
    }

    await this.db.toolkits.put(toolkitModel)
    await this.db.toolkitSummaries.put(toolkitSummaryModel)

    const sectionsForToolkit = this.getSections(toolkit)
    await this.db.sections.bulkAdd(sectionsForToolkit)
  }

  async importToolkits () {
    const toolkits = await this.legacyDb.toolkits.toArray()

    for (var importToolkit of toolkits) {
      const toolkit = await this.db.toolkits.get(importToolkit.Id)
      if (!toolkit) {
        this.importToolkit(importToolkit)
      }
    }

    return toolkits
  }

  async importAudit (audit, toolkit) {
    const dbAnswers = await this.legacyDb.answers.where({ AuditId: audit.Id }).toArray()

    const answers = dbAnswers.filter(a=> {
      if (a.NotAnswsered) {
        return true;
      }
      if (a.Success || a.Success===false) {
        return true;
      }

      return false;
    })
    

    const totalSuccess = answers.filter(a => a.Success === true).length
    const resultSummary = {
        TotalAnswered: answers.length,
        TotalNotApplicable: answers.filter(a => a.NotAnswsered === true).length,
        TotalSuccess: totalSuccess,
        SuccessRatio:
          totalSuccess === 0 ? 0 : (totalSuccess / answers.length) * 100
      }

    const auditModel = {
      AdditionalInformation: audit.AdditionalInformation,
      Address: audit.Contact?.Address,
      AnsweredQuestions: null,
      AuditType: toolkit.ToolkitType,
      AuditorId: audit.Auditor?.Id,
      Contact: { Name: audit.Contact?.Name },
      Created: audit.Created,
      CreatedBy: audit.CreatedBy,
      FacilityId: audit.FacilityId,
      FacilityName: audit.FacilityName,
      Id: audit.Id,
      IsDirty: 0,
      IsIdEmpty: false,
      IsUnread: true,
      LastDate: new Date(),
      LastUpdatedBy: audit.AuditorId,
      LastUpdatedSource: 'mobile',
      LocationId: audit.LocationId,
      LocationName: audit.LocationName,
      Notes: audit.AdditionalInformation,
      OrganisationId: audit.OrganisationId,
      OrganisationName: audit.OrganisationName,
      ResultSummary: resultSummary,
      Scheduled: audit.Scheduled,
      SectionNotes: audit.SectionNotes.map(p => ({
        Id: p.Id,
        Notes: p.Notes
      })),
      Started: audit.Scheduled,
      Status: audit.Status,
      ToolkitId: audit.ToolkitId,
      ToolkitInstanceId: audit.ToolkitInstanceId,
      UpdatedTs: audit.UpdatedTs,
      Version: audit.Version
    }

    const auditInternalId = await this.db.audits.add(auditModel);

    const newAnswers = answers.map(answer => ({
        AuditInternalId: auditInternalId,
        NotAnswsered: answer.NotAnswsered===undefined? null : answer.NotAnswsered,
        QuestionAnswers: answer?.Fields.map(field=> ({
            FieldName: field.FieldName,
            FieldType: field.Type,
            Value: field.Value === undefined ? null : field.Value
        })),
        QuestionId: answer.QuestionId,
        Success: answer.Success === undefined ? null : answer.Success
    }));

    await this.db.answers.bulkAdd(newAnswers);
  }

  async importAudits (toolkits) {
    const audits = await this.legacyDb.audits.toArray()

    for (var importAudit of audits) {
      const audit = await this.db.audits.get({ Id: importAudit.Id})
      if (!audit) {
        const importToolkit = this.getToolkit(toolkits, importAudit)
        this.importAudit(importAudit, importToolkit)
      }
    }
  }

    getToolkit(toolkits, importAudit) {
        return toolkits.find(
            tk => tk.Id === importAudit.ToolkitInstanceId
        )
    }

  async importFromLegacy () {
      this.open();
    const toolkits = await this.importToolkits()
    await this.importAudits(toolkits)
  }

  static async checkExists () {
    return await Dexie.exists(legacyDbName)
  }
}
