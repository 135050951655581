import axios from "axios";
import {urls, apiPath} from "../config/apiConfig";
import Config from "../config/config";
import getStorage from "../storage";
import {setAuditsForUser} from "./auditActions";

export function setAuthenticatedUser(isAuthenticated) {
  return { type: "app/setUserInfo", payload: { isAuthenticated } };
}

export function setUserInfo(user) {
  return { type: "app/setUserInfo", payload: user };
}

export function setLoginError(message) {
  return { type: "app/setUserInfo", payload: { loginError: message } };
}

export function setDisclaimer(html) {
  return { type: "app/setUserInfo", payload: { disclaimer: html } };
}

export function setUserAuth(token) {
  return { type: "app/setUserInfo", payload: { token } };
}

export function setLogout() {
  return { type: "app/logout", payload: {} };
}

export function setOnline() {
  return { type: "app/setIsOnline", payload: true }
}

export function setOffline() {
  return { type: "app/setIsOnline", payload: false }
}

export function loadDisclaimer(url) {
  return function (dispatch) {
    return axios.get(`${Config.apiRoot}${url}`).then(({data}) => {
      dispatch(setDisclaimer(data));
    });
  }
}

export function login  (username, password, rememberMe = true) {
  return async (dispatch) => {
    const body = {
      Email: username,
      Password: password,
      RememberMe: rememberMe
    };

    try {
        const response = await axios.post(`${apiPath}${urls.auth.login}`, body);
        
        if (!response || !response.data) {
            dispatch(setLoginError("No Response"));
        }

        const {data} = response;

        if (!data.Success) {
            dispatch(setLoginError(data.Status));
            return;
        }

        if (data.Configuration.Disclaimer.Show) {
        dispatch(loadDisclaimer(data.Configuration.Disclaimer.Url));
        }

        dispatch(setUserInfo(data.Model))
        dispatch(setUserAuth(data.SessionToken))
    }  
    catch (e) {
        console.log(e,"unhandled exception when logging in")
    }  
  }
}

export function logout() {
  return async function (dispatch) {
    await clearDataAndState();
    dispatch(setLogout());
  }
}

export const clearDataAndState = () => async (dispatch) => {
  await clearData();    
  dispatch(setAuditsForUser([]));
}

async function clearData() { 
  const storage = getStorage();
  await storage.clearDatabase();
}

export function online() {
  return function (dispatch, getState) {
    dispatch({type:"SET_ONLINE"});
    if (getState().appState.userInfo.isAuthenticated) {
     // dispatch({type:"SYNC_AUDITS"});
    }
  }
}
export function offline() {
  return function (dispatch) {
    dispatch({type:"SET_OFFLINE"});
  }
}

export function resetPassword(userName) {
  return async (dispatch) => {
    const path = urls.users.forceResetPassword +`?userNameOrEmail=${userName}&returnUrl=${window.location.origin}`
    const response = await axios.post(`${apiPath}${path}`, {});
    return response;
  }
}