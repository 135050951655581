import React from 'react'
import { PrimaryAppBar } from '../../../components/shared/AppBar'
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { useSelector } from 'react-redux'
import {useDispatch} from "react-redux";
import Switch from '@mui/material/Switch';
import { List, ListSubheader, Paper, ListItem, ListItemSecondaryAction, ListItemText, Divider, ListItemIcon } from '@mui/material';
import { styled } from '@mui/material'
import {useAudits} from "../../../hooks/useAudits";
import { useOnline } from '../../../hooks/useUserInfo';
import { DeleteDataStoreItem } from './DeleteDataStoreListItem';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import CachedIcon from '@mui/icons-material/Cached';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { DeleteCacheListItem } from './DeleteCacheListItem';

const useStyles = makeStyles((theme) => ({    
    content: {    
       height:'100vh'
      }
  }));

  const StyledList = styled(List)(({ theme }) => ({
  
    
  }));
  

export const ProfilePage = () => {
  const classes = useStyles()
  const settings = useSelector( store => store.settings);
  const dispatch = useDispatch();
  const audits = useAudits();
  const isOnline = useOnline()
  const handleThemeChange = (event) => {
    dispatch({type: "WRITE_SETTING", payload:{key:"theme",value:event.target.checked?"dark":"light"}});
  };

  const handleBackgroundSyncChange = (event) => {
    dispatch({type: "WRITE_SETTING", payload:{key:"backgroundSync",value:event.target.checked}});
  };

  const handleClearDataAndState = () => {
    dispatch({type:"CLEAR_DATA_AND_STATE"});
  }

  const handleDeleteCache = () => {
    dispatch({type:"CLEAR_SERVICE_WORKER_CACHE"});
  }

  const handleCheckForUpdate = () => {
    dispatch({type:"CHECK_FOR_UPDATE"});
  }

  const renderSynchronise = () => {
    return <ListItem disabled={audits.synchronizing || !isOnline}  onClick={sync}>    
      <ListItemIcon>
      <CachedIcon />
        </ListItemIcon>  
    <ListItemText 
      primary={"Synchronise"}     
    />  
  </ListItem>;
  }

  const renderCheckForUpdate = () => {
    return <ListItem button disabled={!isOnline} onClick={handleCheckForUpdate}>  
      <ListItemIcon>
      <SystemUpdateAltIcon />
        </ListItemIcon>  
    <ListItemText 
      primary={"Check for Updates"}     
    />
   
  </ListItem>;
  }

  const renderAutoSyncOption = ()=> {
    return <ListItem>
    <ListItemIcon>
      <SwapVertIcon />
    </ListItemIcon>
    <ListItemText id="switch-list-label-sync" primary="Synchronise in Background" />
    <ListItemSecondaryAction>
      <Switch
        edge="end"
        onChange={handleBackgroundSyncChange}
        checked={settings.backgroundSync}
        inputProps={{ 'aria-labelledby': 'switch-list-label-sync' }}
      />
    </ListItemSecondaryAction>
  </ListItem>
  }

  const renderDeleteCacheOption = () => {
    if (true) return null;
    return   <DeleteCacheListItem onDelete={handleDeleteCache}/>;
  }

  const renderDarkThemeOption = ()=> {
    return <ListItem>
    <ListItemIcon>
      <Brightness4Icon />
    </ListItemIcon>
    <ListItemText id="switch-list-label-sync" primary="Dark Mode" />
    <ListItemSecondaryAction>
      <Switch
        edge="end"
        onChange={handleThemeChange}
        checked={settings.theme==="dark"}
        inputProps={{ 'aria-labelledby': 'switch-list-label-sync' }}
      />
    </ListItemSecondaryAction>
  </ListItem>
  }

  //const MAX_REFRESH_VAL = 600;
  
  /*const withValueLimit = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_REFRESH_VAL) return inputObj;
  };

  const handleSyncFrequencyChanged = (values) => {
      const {floatValue} = values;
      dispatch(writeSetting("refreshFrequency",floatValue));
  }*/

  const sync = () => {
    dispatch({type:"SYNC"})
  }
  return (
    <>
      <PrimaryAppBar title="Profile" goBack={true}/>
      
     
      <Paper className={classes.content}>
      <Toolbar />
      <StyledList
     
      aria-labelledby="list-subheader"
      subheader={
        <ListSubheader component="div" id="list-subheader">
         TOOLS
        </ListSubheader>
      }      
    >
      <DeleteDataStoreItem onDelete={handleClearDataAndState}/>
      
    {renderDeleteCacheOption()}
      <Divider/>
       {renderSynchronise()}
      <Divider/>
      {renderCheckForUpdate()}

      <ListSubheader component="div" id="list-subheader">
         OPTIONS
        </ListSubheader>
        {renderAutoSyncOption()}
        <Divider/>
        {renderDarkThemeOption()}

        <ListSubheader component="div" id="list-subheader">
         SYSTEM
        </ListSubheader>
         <ListItem><ListItemText  primary="Version" secondary="Version 4.41" /></ListItem>
       
    </StyledList>
      </Paper>
    </>
  )
}
