import React from 'react'
//import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
//import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useSelector } from 'react-redux'
import { LinearProgress } from '@mui/material'

import styled from 'styled-components'

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginBottom: 20
}));

export default function MigrationAvailableDialog () {
 
  const migrationAvailable = useSelector(
    state => state.appState.migrationAvailable
  )

  //const handleMigrationClick = () => { dispatch({type:'DO_MIGRATION'})}

 // const handleCancelMigrationClick = () => { dispatch({type:'DECLINE_MIGRATION'})}

  if (!migrationAvailable) {
    return null
  }

  return (
    <Dialog open={migrationAvailable}>
      <DialogTitle>Upgrading Database</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A earlier version of icat database is being upgraded. Please wait.
        </DialogContentText>
        <StyledLinearProgress />
      </DialogContent>
    
    </Dialog>
  )
}
