import { Paper, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    panel: {
      padding:theme.spacing(2)
    },
   
  }))

export const NothingHere = () => {
    const classes = useStyles()
    return (
        <Paper className={classes.panel} elevation="0">
      
        <Typography align="center" variant="subtitle2" color="text.secondary" component="div">
        All completed audits have been successfully returned. Go to the console to make further changes and view reports.
          </Typography>
        </Paper>
    )
}