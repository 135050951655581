import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { reduxBatch } from '@manaflair/redux-batch';
import monitorReducersEnhancer from './enhancers/monitorReducer'
import loggerMiddleware from './middleware/logger'
import createRootReducer from './reducers/rootReducer'
import rootSaga from './sagas/rootSaga';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [loggerMiddleware,routerMiddleware(history), sagaMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [reduxBatch,middlewareEnhancer, monitorReducersEnhancer,reduxBatch]

  const composedEnhancers = composeEnhancers(...enhancers)

  const store = createStore(createRootReducer(history), preloadedState, composedEnhancers)
  sagaMiddleware.run(rootSaga)
  

/*  const sagaMiddleware = createSagaMiddleware()
const store = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
)
sagaMiddleware.run(rootSaga)*/

  return store
}