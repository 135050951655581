import React, { useMemo } from 'react'
import { Grid, Container, Typography, ButtonBase } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory, useParams } from 'react-router-dom'
//import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
//import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'

import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
const useStyles = makeStyles(theme => {
  return ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  light: {
    fontWeight: 'normal',
    fontSize: '0.9rem',
    paddingTop: 3,
    fontFamily: theme.typography.fontFamily
  },
  iconPadding: {
    marginTop: theme.spacing(0.5)
  },
  blue: {
    color: '#4285f4'
  },
  notCompliant: {
    color: '#f77c00'
  },
  compliant: {
    color: '#0f9d58'
  },
  questionButton: {
    textAlign: 'left',
    paddingRight: theme.spacing(2)
  }
})
})

export const QuestionItem = ({ question, sectionAnswers, onClick }) => {
  const styles = useStyles()
  const history = useHistory()
  const { id } = useParams()
  const answer = useMemo(() => {
    if (!sectionAnswers) {
      return null
    }

    return sectionAnswers.find(p => p.QuestionId === question.Id)
  }, [sectionAnswers, question])

  const handleQuestionClicked = () => {
      history.push(`/audits/${id}/${question.Id}`)

    //  if (onClick) {
    //      onClick(question)
     // }
  }

  const renderIcon = () => {
    //return <CheckBoxOutlineBlankIcon style={{color:'#ddd'}} className={styles.iconPadding} />
    if (!answer || (answer.NotAnswsered==null && answer.Success==null)) {
      return <CheckBoxOutlineBlankIcon className={styles.iconPadding} />
    }
    // todo
    if (answer.NotAnswsered) {
      return (
        <CheckBoxRoundedIcon
          className={styles.iconPadding + ' ' + styles.blue}
        />
      )
    }

    if (answer.Success) {
      return (
        <CheckBoxRoundedIcon
          className={styles.iconPadding + ' ' + styles.compliant}
        />
      )
    }

    return (
      <CheckBoxRoundedIcon
        className={styles.iconPadding + ' ' + styles.notCompliant}
      />
    )
  }

  return (
    <Container className={styles.root} maxWidth={false} disableGutters={true}>
      <Grid container wrap='nowrap' spacing={1}>
        <Grid item>{renderIcon()}</Grid>
        <Grid item>
          <ButtonBase onClick={handleQuestionClicked} className={styles.questionButton}>
            <Typography
              paragraph={false}
              className={styles.light}
              variant={'h6'}
            >
              {question.QuestionText}
            </Typography>
          </ButtonBase>
        </Grid>
      </Grid>
    </Container>
  )
}