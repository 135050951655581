import {
  call,
  put,
  takeEvery,
  takeLatest,
  select,
  all
} from 'redux-saga/effects'
import getStorage from '../storage'
import { getDisclaimer, postLogin, postResetPassword } from '../api/accountApi'
import { asyncLocalStorage } from '../util/asyncLocalStorage'

function * loadDisclaimer (url) {
  try {
    const { data } = yield call(getDisclaimer, url)
    yield put({ type: 'app/setUserInfo', payload: { disclaimer: data } })
  } catch (e) {
    yield put({ type: 'app/loadDisclaimerFailed', message: e })
  }
}

function * login (action) {
  
  const {username, password, rememberMe=false} = action.payload;
  
  try {
    const body = {
      Email: username,
      Password: password,
      RememberMe: rememberMe
    }

    const response = yield call(postLogin, body)

    if (!response || !response.data) {
      yield put({
        type: 'app/setUserInfo',
        payload: { loginError: 'No response from server' }
      })
      return
    }

    const { data } = response

    if (!data.Success) {
      yield put({
        type: 'app/setUserInfo',
        payload: { loginError: data.Status }
      })
      return
    }

    if (data.Configuration.Disclaimer.Show) {
      yield call(loadDisclaimer, data.Configuration.Disclaimer.Url)
    }
    yield put({ type: 'app/setUserInfo', payload: data.Model })
    yield put({
      type: 'app/setUserInfo',
      payload: { token: data.SessionToken }
    })
  } catch (e) {
    yield put({ type: 'app/loginFailed', message: e })
  }
}

function * clearDataAndState () {
  try {
    yield all([
      call(clearData),
      put({ type: 'audits/clear' }),
      put({ type: 'snapshots/clear' }),
      put({ type: 'auditEntry/clear' }),
    ])
  } catch (e) {
    yield put({ type: 'app/clearDataAndStateFailed', message: e })
  }
}

function* clearServiceWorkerCache() {
 /*async function clearCache(cacheName) {
    if ('caches' in window) {
      return await caches.delete(cacheName);
    } 
  }*/
}

function * clearData () {
  const storage = getStorage()
  yield call([storage,storage.clearDatabase])
  yield call(asyncLocalStorage.setItem, 'icat-user',{})
}

function * logout () {
  yield call(clearDataAndState)
  yield put({ type: 'app/logout' })
}

const getAppStateSelector = state => state.appState

function * online () {
  const { isOnline } = yield select(getAppStateSelector)
  if (!isOnline) {
    yield put({ type: 'app/setIsOnline', payload: true })
  }

  const { userInfo } = yield select(getAppStateSelector)
  if (userInfo && userInfo.isAuthenticated) {
  //  yield put({type:'SYNC_AUDITS'})
  }
}

function * offline () {
  const { isOnline } = yield select(getAppStateSelector)
  if (isOnline) {
    yield put({ type: 'app/setIsOnline', payload: false })
  }
}

function * resetPassword (userName) {
  try {
    const response = yield call(postResetPassword)
    return response
  } catch (e) {
    yield put({ type: 'app/resetPasswordFailed', message: e })
  }
}

async function updateWorkbox() {
  return await window.wb.update()
}

function* checkForUpdate() {
  try {
  const appUpdate = yield call(updateWorkbox)
  console.log(appUpdate);
  }
  catch(e) {
    yield put({ type: 'app/checkForUpdateFailed', message: e })
  }
}

function * applicationSaga () {
    yield takeEvery('RESET_PASSWORD', resetPassword)
    yield takeLatest('OFFLINE', offline)
    yield takeLatest('ONLINE', online)
    yield takeLatest('CLEAR_DATA_AND_STATE', clearDataAndState)
    yield takeLatest('CLEAR_SERVICE_WORKER_CACHE', clearServiceWorkerCache)
    yield takeEvery('LOGOUT', logout)
    yield takeLatest('LOGIN', login)
    yield takeLatest('CHECK_FOR_UPDATE', checkForUpdate)
    yield takeLatest('LOAD_DISCLAIMER', loadDisclaimer)
  }
  
  export default applicationSaga
  