export const AuditStatus = {
    "UNALLOCATED": 0,
    "ALLOCATED": 10,
    "CHECKED_OUT": 20,
    "IN_PROGRESS": 30,
    "READY":39, //  to provide intermediate state on client
    "COMPLETE": 40, // after user pressing done button
    "CANCELLED": 50,
    "APPROVED": 60,
    "ISSUED": 70
}