import React, {useMemo} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useParams } from 'react-router';
import _ from 'lodash'
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position:'fixed',
    width:'100%',
    top: 'auto',
    bottom: 0,
    zIndex:10000
  },
  bar: {
    //backgroundColor: 'white',
   // maxHeight:40,
    
  
  },
 
  title: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight:42,
 height:42
  },
   rightToolbar: {
    marginLeft: "auto",
    marginRight: -12
  },
  menuButton: {
    marginRight: 0,
    marginLeft: -12
  }
}));

export const QuestionAppBar = ({disabled,sections, auditId})=> {
  const classes = useStyles();
  const { question: questionId } = useParams()
  
  const sectionIndex = useMemo(() => {
    if (!sections) {
      return null
    }

    const idx = _.findIndex(sections, section => {
      return section.QuestionIds.includes(questionId);
    })

    return idx;

  },[sections,questionId])

  const section = useMemo(() => {
    if (sectionIndex===undefined || sectionIndex===null || sectionIndex===-1) {
      return null
    }

    const section = sections[sectionIndex];
  const questionIndex = section && section.QuestionIds.indexOf(questionId);

  return {section, questionIndex}

  },[sectionIndex,sections,questionId])
 
  
  const handleBack = () => {
  //  if (onBack) {
  //   // onBack();
  //  }
  }

  const handleForward = () => {
  //  if (onForward) {
     // onForward();
  //  }
  }

  const renderBackAction = () => {
    
    const url = getPreviousUrl();
    if (!url) {
      return null;
    }
    
    return (
      <IconButton
        className={classes.menuButton}
        component={Link}
        to={url}
        disabled={disabled}
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={handleBack}
        color='inherit'
        size="large">
      <ArrowBackOutlinedIcon />
    </IconButton>
    );
  }

  const renderForwardAction = () => {
    
    const url = getNextUrl();
    if (!url) {
      return null;
    }
    
    return (
      <IconButton
        className={classes.menuButton}
        component={Link}
        to={url}
        disabled={disabled}
        aria-label='next question'
        aria-controls='menu-appbar'
        aria-haspopup='false'
        onClick={handleForward}
        color='inherit'
        size="large">
      <ArrowForwardOutlinedIcon />
    </IconButton>
    );
  }

  const getPreviousUrl = () => {
    if (!section) {
      return null;
    }

    if (sectionIndex ===0) {
      if (section.questionIndex===0) {
        return null;
      }
      return `/audits/${auditId}/${section.section.QuestionIds[section.questionIndex-1]}`;
    }

    if (section.questionIndex===0) {
      // point to last question of previous section
      return `/audits/${auditId}/${_.last(sections[sectionIndex-1].QuestionIds)}`;
    }

    return `/audits/${auditId}/${section.section.QuestionIds[section.questionIndex-1]}`
  }

  const getNextUrl = () => {
    if (!section) {
      return null;
    }

    if (sectionIndex === sections.length-1) {
      if (section.questionIndex===section.section.QuestionIds.length-1) {
        return `/audits/${auditId}`;
      }
      return `/audits/${auditId}/${section.section.QuestionIds[section.questionIndex+1]}`;
    }

    if (section.questionIndex=== section.section.QuestionIds.length-1) {
      // point to last question of previous section
      return `/audits/${auditId}/${sections[sectionIndex+1].QuestionIds[0]}`;
    }

    return `/audits/${auditId}/${section.section.QuestionIds[section.questionIndex+1]}`
  }

  return (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.bar}>
        <Toolbar className={classes.toolbar}>
       
       {renderBackAction()}
      <section className={classes.rightToolbar}>
        {renderForwardAction()}
         
        </section>
        </Toolbar>
      </AppBar>
    </div>
  );
}