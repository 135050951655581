import { urls } from '../config/apiConfig'
import request from '../request'
import Config from "../config/config";

  export const getDisclaimer = async url => {
    return request.get(`${Config.apiRoot}${url}`,{baseURL:''})
  }

  export const postLogin = async loginRequest => {
   return await request.post(`${urls.auth.login}`, loginRequest)    
}

export const postResetPassword = async userName => {
  const path = urls.users.forceResetPassword +`?userNameOrEmail=${userName}&returnUrl=${window.location.origin}`
  return await request.post(path);
}