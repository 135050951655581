import React, { useEffect, useMemo, useState } from 'react'
import { PrimaryAppBar } from '../../shared/AppBar'
import { AuditingDrawer } from '../../shared/AuditingDrawer'
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar'
import { Box, List, Paper } from '@mui/material'
import AuditListItem from './AuditListItem'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationActions } from '../../shared/AppBar/ApplicationActions'
import ModifiedExternallyDialog from '../AuditPage/ModifiedExternallyDialog'
import { AuditStatus } from '../../../util/constants'
import { NothingHere } from './NothingInOutbox'
import { NoAuditsAssigned } from './NoAuditsAssigned'

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100vh'
  },
  list: {
    margin: theme.spacing(1)
  },
  noAudits : {
    margin: theme.spacing(2)
  }
}))

export const AuditListPage = ({type}) => {
  const classes = useStyles()
  const [drawerOpen, setdrawerOpen] = React.useState(null)  
  const [expandedAudit, setExpandedAudit] = React.useState(null)
  const [appTitle, setAppTitle] = useState("Audits")
  const audits = useSelector(store => store.audits.auditList)  
  const toolkitSummaries = useSelector(store => store.audits.toolkitSummaries)
  const unopenedCount = useSelector(store => store.audits.unopenedCount)
  const errorState = useSelector(store => store.audits.errorState)
  const dispatch = useDispatch()
    
  useEffect(() => {   
    
    switch(type) {
      case "audit": {
        setAppTitle("Audits");
        break;
      }
      case "snapshots": {
        setAppTitle("Snapshot Audits");
        break;
      }
      case "outbox": {
        setAppTitle("Outbox");
        break;
      }
      default:
        setAppTitle("Audits")
    }

    if (!audits) {
      dispatch({type:"GET_AUDITS"})      
    }  
    
  }, [dispatch, audits, type])

const filteredAudits = useMemo(()=> {

  if (!audits) {
    return null;
  }
  let filtered = audits;
 
    filtered = filtered.filter(a=> {

      if (type && type==="snapshots" && a.AuditType !=="Snapshot") {
        return false;
      }
      if (type && type==="audit" && a.AuditType ==="Snapshot") {
        return false;
      }
      if (type && type==="outbox" && a.Status !== AuditStatus.COMPLETE) {
        return false;
      }
      if (type!=="outbox" && a.Status === AuditStatus.COMPLETE) {
        return false;
      }

      return true;

    });  

  return filtered;

},[audits,type])

  const handleCancelUpdatedExternally = () => dispatch({type:"audits/setErrorState", payload:{"UPDATED_EXTERNALLY":null}});
  const handleCloneFromExternallyUpdated = (auditInternalId) => dispatch({type:"COPY_AUDIT_AND_DISCARD_ORIGINAL", payload:{auditInternalId:auditInternalId}});

  const handleAuditClick = audit => {
    if (audit) {
      dispatch({type:"OPEN_AUDIT",payload:{audit}})      
    }    
  }

  const onAuditExpanded = value => {
    setExpandedAudit(value.isExpanded ? value.auditId : false)
  }

  const RenderNoAudits = () => {
 
    const nothing= !filteredAudits || filteredAudits.length === 0 ;

    //if (nothing) {
   //   return null;
   // }
     
     return <Box className={classes.noAudits}><NoAuditComponent hidden={!nothing} timeout={800} auditView={type}/></Box>
  
  }

  const renderAudits = () => {
    if (!filteredAudits || !toolkitSummaries) {
      return null;
    }
  return (
    <Box className={classes.list}>
      <List>
        {filteredAudits.map(audit => (
          <AuditListItem
            key={'audit-list-item' + audit.InternalId}
            expanded={expandedAudit === audit.InternalId}
            onChange={onAuditExpanded}
            audit={audit}
            onAuditClick={handleAuditClick}
          />
        ))}
      </List>
    </Box>
  )
}

  return (
    <>
      <PrimaryAppBar
        title={appTitle}
        drawerOpen={drawerOpen}
        onDrawerChange={open => setdrawerOpen(open)}
        count={unopenedCount}
        actions={<ApplicationActions/>}
      />
      <AuditingDrawer
        open={drawerOpen||false}
        handleDrawerClose={() => setdrawerOpen(false)}
        handleDrawerOpen={() => setdrawerOpen(true)}
      />
      <Paper className={classes.paper} elevation={0}>
        <Toolbar />
        <RenderNoAudits />
        {renderAudits()}
      </Paper>
      {errorState.UPDATED_EXTERNALLY && <ModifiedExternallyDialog    

        open={errorState && errorState.UPDATED_EXTERNALLY}
        onCancel={handleCancelUpdatedExternally} auditInternalId={errorState.UPDATED_EXTERNALLY.AuditInternalId}
        onCloneAudit={handleCloneFromExternallyUpdated}
      />}
    </>
  )
}

const NoAuditComponent = ({hidden, auditView}) => {
 

  if ( hidden) {
    return null;
  }
  
   switch(auditView) {
     case "snapshots": {
       return <NothingHere/>
     }
     case "outbox": {
       return <NothingHere/>
     }
     case "audit": {
       return <NoAuditsAssigned/>
     }
     default:
       return null;
   }    
 }