import { urlsV2 } from '../config/apiConfig'
import request from '../request'

export const fetchAudits = async auditIds => {
  return request.post(urlsV2.audits.fetch, {
    AuditIds: auditIds
  })
}

export const checkAudits = async (localAudits) => {
   return await request.post(urlsV2.audits.check, {
    CurrentAudits: localAudits
      .filter(a => a.Id || a.CopyOfAuditId)
      .map(a => ({ AuditId: a.Id||a.CopyOfAuditId, Version: a.Version }))
  })
}

export const fetchToolkitInstanceById = async id => {
    const { data: tki } = await request.get(`${urlsV2.toolkits.byID}/${id}`)
    return tki;
}

export const fetchSnapshots = async excludeToolkitIds => {
  const { data: tkiModel } = await request.post(urlsV2.toolkits.snapshots, excludeToolkitIds)
  return tkiModel;
}

export const pushAudit = async auditPushModel => {
  const { data: auditPushResponse } = await request.post(`${urlsV2.audits.push}/${auditPushModel.AuditId}`, auditPushModel)
  
  if (auditPushResponse?.Audit?.SectionNotes) {
  auditPushResponse.Audit.SectionNotes = auditPushResponse.Audit.SectionNotes.map(p=> ({
    Notes: p.SectionNotes,
    Id: p.SectionId
  }))
}

  return auditPushResponse;
}