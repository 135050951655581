import React, { useMemo } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Divider from '@mui/material/Divider'
import {
  Badge,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  Typography
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded'
import { useSelector } from 'react-redux'
import {CircularProgress} from '../../shared/CircularProgress'
import FlashOnRoundedIcon from '@mui/icons-material/FlashOnRounded';
import { AuditStatus } from '../../../util/constants'
import AuditListItemSummaryCard from './AuditListItemSummaryCard'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  icon: {
    minWidth: '42px'
  },
  status: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginRight: theme.spacing(2)
  },
  location: {
    lineHeight:'1.35'
  },
  toolkit: {
    lineHeight: '1.33',
    fontSize:'0.675rem'
  },
  customBadge: {
    backgroundColor: "green",
    color: "white"
  }
}))

const getIconStyle = (audit) => {
  let style = {};

    switch(audit.Status) {
      case AuditStatus.COMPLETE:
        style.color = 'green';
        break;
        case AuditStatus.READY:
        style.color = '#81c784';
        break;
        default:
          style.color = '#ccc'

    }

    return style;
}

const AuditListItem = React.memo(({
  expanded,
  audit,
  onChange,
  onAuditClick
})=> {
  const theme = useTheme()
  const classes = useStyles()
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'))
  const toolkitSummaries = useSelector(store => store.audits.toolkitSummaries)
  const isSnapshot = audit && audit.AuditType==="Snapshot";
  const toolkit = useMemo(() => {
    if (!toolkitSummaries || !toolkitSummaries.length) {
      return null
    }
    return toolkitSummaries.find(p => p.Id === audit.ToolkitInstanceId)
  }, [toolkitSummaries, audit])

  const handleToggleOpen = event => {
    onChange({ auditId: audit.InternalId, isExpanded: !expanded })
  }
  const handleAuditClick = () => {
    if (!onAuditClick) {
      return
    }

    onAuditClick(audit)
  }

  

  const iconStyle = getIconStyle(audit);

  const renderSummary = () => {

    if (!audit || !audit.ResultSummary || !toolkit) {
      return null;
    }

    const pct =Math.ceil((audit.ResultSummary.TotalAnswered/toolkit.QuestionCount) *100)
    
    if (audit.ResultSummary) {
      return (
        <CircularProgress
        strokeWidth="3"
        sqSize="30"
        percentage={pct}/>
      )
    }
    return null;
  }  

  const getIcon = () => {


    if (audit.UpdatedExternally) {
      return <ErrorRoundedIcon color='error' />
    }

    if (audit.IsRecalled) {
      return <ErrorRoundedIcon color='error' />
    }

    if (isSnapshot) {
        return <FlashOnRoundedIcon style={iconStyle}/>
    }
    else {

      if (!audit.Id) {
        return <NoteAddRoundedIcon style={iconStyle} />
      }

      return <DescriptionRoundedIcon style={iconStyle} />
    }
  }

  return <>
    <ListItem
      dense={smallDevice}
      button
      onClick={handleAuditClick}
      key={audit.InternalId}
    >
      <ListItemIcon classes={{ root: classes.icon }}>
        <Badge color='secondary' variant='dot' invisible={audit.Status!==AuditStatus.ALLOCATED}>
          {getIcon()}
        </Badge>
      </ListItemIcon>
      <ListItemText>
        <Grid item xs={12} sm container>
          <Grid item xs container direction='column' spacing={2}>
            <Grid item xs>
              <Typography variant='subtitle1' className={classes.location}>
                {audit.LocationName}
              </Typography>
              <Typography variant='body2' color='textSecondary' className={classes.toolkit}>
                {toolkit && toolkit.InstanceName}
              </Typography>
            </Grid>
          </Grid>
          <Grid item classes={{ root: classes.status }}>
            {renderSummary()}
          </Grid>
       
        </Grid>
      </ListItemText>
      <ListItemSecondaryAction>
      <Badge invisible={audit.Status < AuditStatus.READY } classes={{ badge: classes.customBadge }} variant="dot">
        <IconButton
          color='primary'
          aria-label='expand'
          onClick={handleToggleOpen}
          size="large">
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        </Badge>
      </ListItemSecondaryAction>
    </ListItem>
    <Collapse in={expanded} timeout='auto' unmountOnExit>
   
      <AuditListItemSummaryCard audit={audit}/>

      
     
    </Collapse>
    <Divider />
  </>;
});

export default AuditListItem