import { Badge, Fade, IconButton, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux'

import { useOnline } from '../../../hooks/useUserInfo'

import CircularProgress from '@mui/material/CircularProgress'

import React from 'react'

import { useSyncStatus } from '../../../hooks/useAudits'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import { AuditDetailDialog } from '../../pages/AuditPage/AuditDetailsDialog'

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(0),
    position: 'relative'
  },
  syncProgress: {
    color: theme.palette.info.light,
    position: 'absolute',
    top: 4,
    left: 5,
    zIndex: 1
  },
  spinner: {
    marginRight: theme.spacing(2),
    marginLeft: '8px',
    color:'white'
  }
}))

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
   backgroundColor:'white'
  },
}));


export const AuditActionsBar = ({ audit }) => {
  
  const dispatch = useDispatch()
  
  const isOnline = useOnline()
  
  const [detailsOpen, setDetailsOpen] = React.useState(null)
  
  const classes = useStyles()
  const isSynchronising = useSyncStatus()
  const isSaving = useSelector(state=>state.auditEntry.saving)
 
  const handleSave = () => {
    dispatch({type:"PUSH_AUDITS",payload: [audit.InternalId]})
    }

  const handleShowAuditDetails = () => {
    setDetailsOpen(true);
    
  }

  const handleDetailsClosed = () => {
    setDetailsOpen(false);
  }

  const renderSave = () => {    

    if (!audit || (audit && audit.AuditType==="Snapshot")) {
      return null;
    }

    if (isSaving) {
      return  <Fade in><CircularProgress className={classes.spinner} size={24}
      thickness={8}  /></Fade>
    }

    return (
      <Fade in><IconButton
        disabled={isSynchronising || !isOnline || !(audit && audit.IsDirty)}
        aria-label='save audit'
        aria-controls='menu-appbar'
        aria-haspopup='false'
        onClick={handleSave}
        color='inherit'
        size="large">
        <SaveRoundedIcon />
      </IconButton></Fade>
    );
  }

  if (!audit){
    return null;
  }

  return <>
    {detailsOpen && <AuditDetailDialog audit={audit} onClose={handleDetailsClosed}/>}
    <div className={classes.wrapper}>
   
    <IconButton
      aria-label='account of current user'
      aria-controls='menu-appbar'
      aria-haspopup='true'
      onClick={handleShowAuditDetails}
      color='inherit'
      size="large">
       <StyledBadge invisible={!audit.Notes} variant="dot">  <AssignmentRoundedIcon /></StyledBadge>

  </IconButton>

    
    </div>
   
    {renderSave()}
  </>;
}
