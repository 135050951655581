const shouldBeImplemented = () => console.error(new Error('not implemented'));


export default class BaseStorage {

  /**
   * @param {AuditFilterModel} [filterModel]
   * @returns {Promise<AuditViewModel[]>}
   * */
  async getAudits(filterModel) { shouldBeImplemented() }
  async getAuditKeys() { shouldBeImplemented() }

  /**
   * @param {String} auditId
   * @returns {Promise<AnswerViewModel[]>}
   */
  async getAuditAnswers(auditId) { shouldBeImplemented() }

  /**
   * @returns {Promise<LocationViewModel[]>}
   * */
  async getLocations(filterModel) { shouldBeImplemented() }

  /**
   * @param {String} [type]
   * @returns {Promise<ToolkitInstance[]>}
   * */
  async getToolkitInstances(type) { shouldBeImplemented() }

  async getToolkitInstanceKeys() { shouldBeImplemented() }

  async getAnswersForAudit(auditInternalId) { shouldBeImplemented() }

  async upsertSection(section) { shouldBeImplemented() }

  async deleteAuditByAuditId(auditId) { shouldBeImplemented() }
 
  async getDb() { shouldBeImplemented() }

  async getSectionsForToolkitInstance(toolkitInstanceId) { shouldBeImplemented() }

  /**
   * @param {String} auditId
   * @param {String} questionId
   * @param {String} fieldId
   * @param {String | Number | Boolean} value
   * @returns {Promise<undefined>}
   * */
  async setAuditQuestionAnswer(auditId, questionId, fieldId, value) { shouldBeImplemented() }

  async writeAuditQuestionAnswer(auditInternalId, questionAnswer, field, value) { shouldBeImplemented() }

  /**
   * @param {AuditViewModel} audit
   * @returns {Promise<undefined>}
   * */
  async upsertAudit(audit) { shouldBeImplemented() }

  async updateAudits(queryModel, auditUpdateModel) { shouldBeImplemented() }

  async deleteAudit(internalId) { shouldBeImplemented() }
  /**
   * @param {LocationViewModel} location
   * @returns {Promise<undefined>}
   * */
  async upsertLocation(location) { shouldBeImplemented() }

  async upsertRoom(room) { shouldBeImplemented() }
  
  /**
   * @param {ToolkitInstance} toolkitInstance
   * @returns {Promise<undefined>}
   * */
  async upsertToolkitInstance(toolkitInstance) { shouldBeImplemented() }

   /**
   * @param {ToolkitInstanceSummary} toolkitInstance
   * @returns {Promise<undefined>}
   * */
  async upsertToolkitInstanceSummary(toolkitInstanceSummary) { shouldBeImplemented() }

  async clearDatabase() { shouldBeImplemented() }
}

/**
 * @typedef {Object} Auditor
 * @property {String} Id
 * @property {String} Name
 * @property {String} Email
 * */
/**
 * @typedef {Object} Address
 * @property {String} AddressLine1
 * @property {String} AddressLine2
 * @property {String} TownCity
 * @property {String} County
 * @property {String} PostCode
 * */
/**
 * @typedef {Object} ResultSummary
 * @property {Number} Questions
 * @property {Object} Answered
 * @property {Number} Answered.total
 * @property {Number} Answered.compliant
 * @property {Number} Answered.notCompliant
 * @property {Number} Answered.notApplicable
 * */

/**
 * @typedef {Object} Contact
 * @property {Address} Address
 * @property {String} Name
 * */

/**
 * @typedef {Object} AuditViewModel
 * @property {String} Id
 * @property {String} ToolkitInstanceId
  * @property {String} UpdatedTs
 * @property {String} OrganisationId
 * @property {String} OrganisationName
 * @property {String} FacilityId
 * @property {String} FacilityName
 * @property {String} LocationId
 * @property {String} LocationName
 * @property {String} Created
 * @property {String} Scheduled
 * @property {Auditor} Auditor
 * @property {Contact} Contact
 * @property {String} ToolkitId
 * @property {Number} Status
 * @property {String} Type
 * @property {String} StatusDescription
 * @property {String} AdditionalInformation
 * @property {Number} Version
 * @property {Number} InternalId
 * @property {ResultSummary} ResultSummary
 * */

/**
 * @typedef {Object} FetchResponse
 * @property {String[]} AgreementIds - toolkit ids
 * @property {String[]} NewAudits - new assigned audit ids
 * @property {String[]} RecalledAuditIds - reassigned audit ids
 * @property {String[]} UpdatedAuditIds - updated audit ids
 * */


/**
 * @typedef {Object} QuestionAnswer
 * @property {Number | String | Boolean | Date} Value
 * @property {String} FieldName
 * @property {String} FieldType
 * */

/**
 * @typedef {Object} AnswerViewModel
 * @property {String} QuestionId
 * @property {Boolean} Success
 * @property {Boolean} NotAnswered
 * @property {QuestionAnswer[]} QuestionAnswers
 * * */


/**
 * @typedef {Object} Facility
 * @property {String} OrganisationId
 * @property {String} OrganisationName
 * @property {Address} Address
 * @property {String} Name
 * @property {String} Reference
 * @property {String} PhoneNumber
 * @property {String} ContactName
 * @property {String} Id
 * */


/**
 * @typedef {Object} LocationViewModel
 * @property {String} OrganisationId
 * @property {String} Name
 * @property {String} Reference
 * @property {String} Phone
 * @property {String} ContactName
 * @property {String} ContactEmail
 * @property {String} SecondaryContactName
 * @property {String} SecondaryContactEmail
 * @property {String} SecondaryContactTelephone
 * @property {String} Notes
 * @property {String[]} Tags
 * @property {String} Id
 * @property {String} Id
 * @property {Facility} Facility
 * @property {Boolean} UpdatedExternally
 * */

/**
 * @typedef {Object} ToolkitInstance
 * @property {String} Id
 * */


/**
 * @typedef {Object} ToolkitInstanceSummary
 * @property {String} Id
 * @property {String} InstanceName
 * */



/**
 * @typedef {Object} AuditFilterModel
 * @property {AUDIT_TYPES} Type
 * @property {Number} InternalId
 * */



// ------------------
/**
 * TBD
 * @typedef {Object} ComparedAudits
 * @property {String[]} newAudits - ids not fetched audits
 * @property {String[]} updatedAudits - ids of updated audits and not processed
 * @property {String[]} recalledAudits - ids reassigned audits and not processed
 * */

/**
 * @typedef {Object} ComparedToolkits
 * @property {String[]} newToolkits - should be fetched and saved
 * @property {String[]} nonReferencedAudits - should be removed from the storage
 * */

