import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';

export default function ApplicationUpdateAvailableDialog() {
  
    const updateAvailable = useSelector(state => state.appState.installed)
  const handleClickRefresh = () => {
 window.wb.addEventListener('controllerchange', (event) => {
   console.log("service worker event - CONTROLLING")
    window.location.reload();

    });
    console.log("handle update event")

    window.wb.messageSkipWaiting();
    window.location.reload();
  };

  if (!updateAvailable) {
      return null;
  }

  return (

      <Dialog
        open={updateAvailable}      
      >
        <DialogTitle >App update available</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The app will now refresh to update with latest version.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
      
          <Button onClick={handleClickRefresh} color="primary" autoFocus>
            Reload
          </Button>
        </DialogActions>
      </Dialog>
  );
}
