import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  Typography,
  IconButton,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {styled} from '@mui/material'
import { AddCircle, Cancel } from '@mui/icons-material'
import { Alert } from '@mui/material';

const useStyles = makeStyles(theme => ({
  dialogAppBar: {
    position: 'relative'
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  title: {
    paddingBottom: 0
  }
}))

export const AuditDetailDialog = ({ onClose, audit }) => {
  const classes = useStyles()
  const [notes, setNotes] = React.useState(audit.Notes)
  const [roomName, setRoomName] = React.useState(audit.RoomName)
  const [roomRef, setRoomRef] = React.useState(audit.RoomReference)
  const [roomId, setRoomId] = React.useState(audit.RoomId)
  const [createRoom, setCreateRoom] = React.useState()
  const [roomError, setRoomError] = React.useState()
  const [accompaniedBy, setAcccompaniedBy] = React.useState(audit.AccompaniedBy)
  const dispatch = useDispatch()
  const rooms = useSelector(store => store.auditEntry.rooms)

  const filteredRooms = useMemo(()=> {
    if (!audit || !rooms || rooms.length===0) {
      return [];      
    }

    return rooms.filter(p=>p.LocationId === audit.LocationId);
  },[rooms,audit])
  
  const handleOnNew = () => {
    setCreateRoom(true);
    setRoomId('');
  }

  const handleCancelNewRoom = () => {
    setRoomName('');
    setRoomRef('');
    setRoomId('');
    setCreateRoom(false);
  }

  useEffect(()=> {
    if (roomError && roomRef) {
      setRoomError('');
    }

  },[roomError, roomRef])

  useEffect(()=> {
    if (roomId) {
     // setCreateRoom(true);
    }
    else if (roomRef || roomName) {
     setCreateRoom(true);
      
    }
  },[roomRef, roomName, roomId])

  const handleSave = () => {

    if (createRoom && !roomRef) {
      setRoomError("You must add a reference for the room")
      return;
    }

    onClose && onClose()
    setTimeout(
      () =>
        dispatch({
          type: 'UPDATE_AUDIT',
          payload: {
            InternalId: audit.InternalId,
            AccompaniedBy: accompaniedBy,
            Notes: notes,
            RoomId: roomId,
            RoomReference: roomRef,
            RoomName: roomName,
            IsDirty: 1
          }
        }),
      0
    )
  }

  const renderRoomEntry = () => {
    if (createRoom) {
      return      <FieldContainer>
      
      <RoomReferenceInput type="search" autoComplete='off' value={roomRef} onChange={(e)=>setRoomRef(e.target.value)} id="outlined-basic" label="Reference" variant="outlined" inputProps={{ style: { autoComplete:'none', textTransform: "uppercase" } }} />
      <RoomNameInput type="search" autoComplete='off' value={roomName} onChange={(e)=>setRoomName(e.target.value)} id="outlined-basic" label="Room Name" variant="outlined"  inputProps={{ style: { autoComplete:'none'} }} />
    <CancelCreateRoomButton onClick={handleCancelNewRoom}><Cancel/></CancelCreateRoomButton>
      </FieldContainer>
    }
    else {

      if (!rooms || rooms.length===0) {
        return <NewRoomButton  color="primary"  startIcon={<AddCircle />}  onClick={handleOnNew}>Create a new room</NewRoomButton>
      }

      return     <FieldContainer>     
      <FillWidthField>       
              <Select value={roomId} onChange={(e)=> setRoomId(e.target.value)} variant='outlined' labelId="room-select-label"
          id="room-select" placeholder='Room'>
          <MenuItem id={'none'} value={''}><em>None</em></MenuItem>
                  {filteredRooms.map(p=><MenuItem id={p.Id} value={p.Id}>{p.Name}</MenuItem>)}                
              </Select>
              </FillWidthField>
      <NewRoomButton  color="primary"  startIcon={<AddCircle />}  onClick={handleOnNew}>New</NewRoomButton>
      
              </FieldContainer>
             
    }
  }

  return (
    <Dialog
      fullWidth={'sm'}
      // maxWidth={"sm"}
      open={true}

      //  TransitionComponent={Transition}
    >
      <DialogTitle className={classes.title} id='form-dialog-title'>
        Details
      </DialogTitle>
      <DialogContent>
        <TextField
          variant='outlined'
          autoComplete='off'
          autoFocus
          margin='dense'
          id='accompaniedBy'
          label='Accompanied By'
          type='text'
          value={accompaniedBy}
          onChange={event => setAcccompaniedBy(event.target.value)}
          fullWidth
        />

        <TextField
          variant='outlined'
          rows={5}
          multiline
          onChange={event => setNotes(event.target.value)}
          margin='dense'
          id='notes'
          value={notes}
          label='Notes'
          type='text'
          fullWidth
        />
        <RoomContainer>
        <Typography>Room Details</Typography>
      {renderRoomEntry()}
      {roomError && <Alert severity="error">{roomError}</Alert>}
      </RoomContainer>
      
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleSave} color='primary'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}


const FillWidthField = styled(FormControl)({
  flex:1
})

const NewRoomButton = styled(Button)({
  
})

const RoomReferenceInput = styled(TextField)({
  width:100,
  marginRight:10
})

const RoomNameInput = styled(TextField)({
  flex:1
})

const CancelCreateRoomButton = styled(IconButton )({
  wifth:90
})

const FieldContainer = styled('div')({
  width:'100%',
  marginTop:'10px',
  display:'flex',
  flexDirection: 'row',
alignContent: 'center',
justifyContent: 'center',
alignItems: 'center'
})
const RoomContainer = styled('div')({
});
