import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import appStateReducer from './appStateReducer';
import auditEntryReducer from './auditEntryReducer';
import auditReducer from './auditReducer';
import settingsReducer from './settingsReducer';
import snapshotReducer from './snapshotReducer';

/*export default function rootReducer(state = {}, action) {  
  return {    
    appState: appStateReducer(state.appState, action),
    audits: auditReducer(state.audits, action),
    settings: settingsReducer(state.settings, action),
    auditEntry: auditEntryReducer(state.auditEntry,action)
  }
};*/

const createRootReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    appState: appStateReducer,
    audits:auditReducer,
    settings:settingsReducer,
    auditEntry: auditEntryReducer,
    snapshots: snapshotReducer
  })
}

export default createRootReducer;