import {
  call,
  put,
  takeEvery,
  takeLatest,
  select,
  all
} from 'redux-saga/effects'
import {
  checkAudits,
  fetchAudits,
  fetchSnapshots,
  fetchToolkitInstanceById,
  pushAudit
} from '../api/auditApi'
import getStorage from '../storage'
import _ from 'lodash'
import { AuditStatus } from '../util/constants'

function * getAuditsWithToolkits () {
  yield all([call(getAudits), call(setToolkitSummariesFromStore)])
}

function * getAudits () {
  try {
    const audits = yield call(queryAuditsFromStore)
    yield all([
      put({ type: 'audits/setAuditsForUser', payload: audits }),
      call(countAudits)
    ]);
  } catch (e) {}
}

function * queryAuditsFromStore (action) {
  try {
    const storage = getStorage()
    return yield call([storage, storage.getAudits], action && action.payload)
  } catch (e) {
    yield put({ type: 'audits/queryAuditsFromStoreFailed', message: e })
    return []
  }
}

function * saveAuditsToStore (audits) {
  try {
    const storage = getStorage()

    const updates = audits.map(audit =>
      call([storage, storage.upsertAudit], audit)
    )
    yield all(updates)    
  } catch (e) {
    yield put({ type: 'audits/saveAuditsToStoreFailed', message: e })
  }
}

function * saveLocationsToStore (locations) {
  try {
    const storage = getStorage()
    const updates = locations.map(location =>
      call([storage, storage.upsertLocation], location)
    )
    yield all(updates)
  } catch (e) {
    yield put({ type: 'audits/saveLocationsToStoreFailed', message: e })
  }
}

function * saveRoomsToStore (rooms) {
  try {
    const storage = getStorage()
    const updates = rooms.map(room => call([storage, storage.upsertRoom], room))

    yield all(updates);
    
  } catch (e) {
    yield put({ type: 'audits/saveRoomToStoreFailed', message: e })
  }
}

function * setUpdatedExternallyStatusForAudits (updateAuditIds) {
  try {
    if (!updateAuditIds) {
      return
    }
    const updates = updateAuditIds.map(auditId =>
      call(updateAuditRecord, {payload: { Id: auditId, UpdatedExternally: true }})
    )
    yield all(updates)
  } catch (e) {
    yield put({
      type: 'audits/setUpdatedExternallyStatusForAuditsFailed',
      message: e
    })
  }
}

function * updateAuditRecord (action) {
  try {
    const storage = getStorage()
    const updatedAudit = yield call(
      [storage, storage.upsertAudit],
      action.payload
    )
    yield all([
      put({ type: 'audits/updateAudits',  payload: {filter: {InternalId:updatedAudit.InternalId}, updateModel:updatedAudit} }),
      call(countAudits)
    ])
  } catch (e) {
    yield put({ type: 'audits/updateAuditRecordFailed', message: e })
  }
}

const getAuditsSelector = state => {
  return state.audits
}
function * syncAudits () {
  const { synchronizing } = yield select(getAuditsSelector)
  if (synchronizing === true) {
    console.warn(
      'skip sync audits because it is already in synchronizing state'
    )
    return
  }

  yield put({ type: 'audits/setAuditSyncStatus', payload: true })
  
  const localAudits = yield call(queryAuditsFromStore)

  const { data: checkData } = yield call(checkAudits, localAudits)

  if (checkData) {
    yield call(syncNewAudits, checkData.NewAudits)
    yield call(setUpdatedExternallyStatusForAudits, checkData.UpdatedAuditIds)
    yield call(syncRecalledAudits, checkData.RecalledAuditIds)
  }

  yield all([
    call(countAudits),
    put({ type: 'audits/setAuditSyncStatus', payload: false }),
  ])
    
}

function * syncRecalledAudits (recalledAuditIds) {
  try {
    if (!recalledAuditIds || recalledAuditIds.length===0) {
      return
    }

    yield all(recalledAuditIds.map(auditId => call(removeAudit, auditId)))
  } catch (e) {
    yield put({ type: 'audits/syncRecalledAuditsFailed', message: e })
  }
}

function * removeAudit (auditId) {
  try {
    // don't remove actively edited
    const currentlyEditingAudit = yield select(state => state.auditEntry.audit)

    if (currentlyEditingAudit && auditId === currentlyEditingAudit.Id) {
      return;
    }
    const storage = getStorage()
    const [audit] = yield call([storage, storage.getAudits], { Id: auditId })
    if (audit) {
      yield call([storage, storage.deleteAudit], audit.InternalId)
      yield put({ type: 'audits/removeAudit', payload: audit.InternalId })
    }
  } catch (e) {
    yield put({ type: 'audits/removeAuditFailed', message: e })
  }
}

function * removeAuditById (internalId) {
  try {
    const storage = getStorage()

    yield call([storage, storage.deleteAudit], internalId)
    yield put({ type: 'audits/removeAudit', payload: internalId })
  } catch (e) {
    yield put({ type: 'audits/removeAuditFailed', message: e })
  }
}


function * syncNewAudits (newAuditIds) {
  try {
    if (!newAuditIds || !newAuditIds.length) {
      return
    }

    const { data: fetchResponseModel } = yield call(fetchAudits, newAuditIds)
 

    const viewModels = mapFetchResponseModelToViewModels(fetchResponseModel)

    yield call(
      syncToolkitInstances,
      fetchResponseModel.AuditsRaw.map(audit => audit.ToolkitInstanceId)
    )
    const toolkitInstances = yield call(setToolkitSummariesFromStore)

    viewModels.audits = viewModels.audits.filter(p=> toolkitInstances.some(t=>t.Id===p.ToolkitInstanceId))

    for (const audit of viewModels.audits) {
      audit.IsUnread = true
    }

    yield all([
      call(saveAuditsToStore, viewModels.audits),
      call(saveLocationsToStore, viewModels.locations),    
      call(saveRoomsToStore, viewModels.rooms)
    ])
    yield put({ type: 'audits/addAuditRecords', payload: viewModels.audits })
  } catch (e) {
    yield put({ type: 'audits/syncNewAuditsFailed', message: e })
  }
}

function * syncToolkitInstances (toolkitInstanceIds) {
  try {
    const toolkitsNeeded = yield call(
      determineMissingToolkitInstanceIds,
      toolkitInstanceIds
    )
    yield all(toolkitsNeeded.map(tki => call(fetchAndSaveToolkitInstance, tki)))
  } catch (e) {
    yield put({ type: 'audits/syncToolkitInstancesFailed', message: e })
  }
}

function * determineMissingToolkitInstanceIds (toolkitInstanceIds) {
  try {
    const storage = getStorage()
    const currentToolkits = yield call([
      storage,
      storage.getToolkitInstanceKeys
    ])
    const toolkitsNeeded = _.uniq(
      toolkitInstanceIds.filter(x => !currentToolkits.includes(x))
    )
    return toolkitsNeeded
  } catch (e) {
    yield put({
      type: 'audits/determineMissingToolkitInstanceIdsFailed',
      message: e
    })
  }
}

function * setToolkitSummariesFromStore () {
  try {
    const storage = getStorage()
    const tkiSummariesFromStore = yield call([
      storage,
      storage.getToolkitInstanceSummaries
    ])

    yield put({
      type: 'audits/setToolkitSummaries',
      payload: tkiSummariesFromStore
    })

    return tkiSummariesFromStore
  } catch (e) {
    yield put({ type: 'audits/setToolkitSummariesFromStoreFailed', message: e })
  }
}

function mapFetchResponseModelToViewModels (fetchData) {
  const locations = mapLocationsToViewModels(fetchData)
  const audits = mapAuditsToViewModels(fetchData)
  const rooms = mapRoomsToViewModels(fetchData);
  return { audits, locations, rooms }
}

function mapRoomsToViewModels(fetchData) {
  const rooms = fetchData.Rooms.map(r=> {
    return {
      LocationId : r.LocationId,
      Name: r.Name,
      Reference: r.Reference,
      Id: r.Id
    }
  })

  return rooms;
}

function mapLocationsToViewModels (fetchData) {
  return fetchData.Locations.map(location => {
    const org = fetchData.Organisations.find(
      o => o.Id === location.OrganisationId
    )
    const facility = fetchData.Facilities.find(
      f => f.Id === location.FacilityId
    )

    const locationViewModel = {
      ...location,
      Facility: {
        ...facility,
        OrganizationName: org.Name
      }
    }
    return locationViewModel
  })
}

function mapAuditsToViewModels (fetchData, result) {
  return fetchData.AuditsRaw.map(audit => {
    const org = fetchData.Organisations.find(o => o.Id === audit.OrganisationId)
    const facility = fetchData.Facilities.find(f => f.Id === audit.FacilityId)
    const location = fetchData.Locations.find(l => l.Id === audit.LocationId)

    const sectionNotes = audit.SectionNotes?.map(p=> ({ Id: p.SectionId, Notes:p.SectionNotes}))

    return mapAuditToViewModel({ ...result, ...audit }, org, location, facility,  sectionNotes)
  })
}

function mapAuditToViewModel (audit, org, location, facility, sectionNotes) {
  return {
    ...audit,
    SectionNotes: sectionNotes,
    OrganisationName: org.Name,
    LocationName: location.Name,
    FacilityName: facility.Name,
    Address: facility.Address || org.Address || null,
    Contact: {
      Name: facility.ContactName
    }
  }
}

function * saveToolkitInstance (toolkitInstance) {
  try {
    const storage = getStorage()
    const sections = toolkitInstance.Sections.map((s, idx) => {
      return {
        SectionId: s.Id,
        ToolkitInstanceId: toolkitInstance.Id,
        QuestionIds: toolkitInstance.Questions.filter(
          q => q.SectionId === s.Id
        ).map(q => q.Id),
        Index: idx
      }
    })

    const tkiSummary = buildTkiSummaryModel(toolkitInstance)

    yield all([
      call([storage, storage.upsertToolkitInstance], toolkitInstance),
      call([storage, storage.upsertToolkitInstanceSummary], tkiSummary),
      ...sections.map(section =>
        call([storage, storage.upsertSection], section)
      )
    ])
  } catch (e) {
    yield put({ type: 'audits/SaveToolkitInstanceFailed', message: e })
  }
}

function * fetchAndSaveToolkitInstance (toolkitInstanceId) {
  try {
    const tki = yield call(fetchToolkitInstanceById, toolkitInstanceId)

    yield call(saveToolkitInstance, tki)
  } catch (e) {
    yield put({ type: 'audits/fetchAndSaveToolkitInstanceFailed', message: e })
  }
}

function buildTkiSummaryModel (tki) {
  return {
    Id: tki.Id,
    InstanceName: tki.InstanceName,
    QuestionCount: tki.Questions.length,
    ToolkitType: tki.ToolkitType || 'Audit',
    ToolkitId: tki.ToolkitId
  }
}

function * discardAudit (auditId) {
  try {
    const storage = getStorage()
    const { AuditsRaw: audits } = yield call(fetchAudits, [auditId])

    if (audits && audits.length) {
      let audit = audits[0]
      yield call([storage, storage.deleteAuditByAuditId], auditId)
      audit = yield call(enrichAuditModel, audit)
      audit = yield call([storage, storage.upsertAudit], audit)

      const answers = yield call(
        [storage, storage.getAnswersForAudit],
        audit.InternalId
      )

      yield put({ type: 'audits/replaceAudit', payload: audit })
      return { audit, answers }
    } else {
      yield call([storage, storage.deleteAuditByAuditId], auditId)
      return null
    }
  } catch (e) {
    yield put({ type: 'audits/discardAuditFailed', message: e })
  }
}

function * cloneAndDiscardAudit (auditInternalId) {
  try {
    const storage = getStorage()
    const newAuditModel = yield call(cloneAudit, auditInternalId)
    yield all([
      call([storage, storage.deleteAudit], auditInternalId),
      put({ type: 'audits/removeAudit', payload: auditInternalId })
    ])
    return newAuditModel
  } catch (e) {
    yield put({ type: 'audits/cloneAndDiscardAuditFailed', message: e })
  }
}

function * cloneAudit (auditInternalId) {
  try {
    const storage = getStorage()
    const [audit, answers] = yield all([
      call([storage, storage.getAudit], auditInternalId),
      call([storage, storage.getAnswersForAudit], auditInternalId)
    ])

    let newAudit = _.cloneDeep(audit)
    let newAnswers = _.cloneDeep(answers)

    newAudit.UpdatedExternally = false
    newAudit.Version = -1
    newAudit.UpdatedTs = new Date()
    newAudit.CreatedTs = new Date()
    newAudit.CopyOfAuditId = audit.Id
 // don't write answers from audit record, use the curerent answer set below    newAudit.IsDirty = trie
    newAudit.AnsweredQuestions = null
    newAudit.IsDirty = 1
    newAudit.IsUnread = true
   
    delete newAudit.Id
    delete newAudit.InternalId

    newAudit = yield call([storage, storage.upsertAudit], newAudit)
    newAnswers.forEach(answer => {
      answer.AuditInternalId = newAudit.InternalId
    })

    yield all(
      newAnswers.map(answer =>
        call(
          [storage, storage.writeAuditQuestionAnswer],
          newAudit.InternalId,
          answer
        )
      )
    )

    yield put({ type: 'audits/replaceAudit', payload: newAudit })

    return { audit: newAudit, answers: newAnswers }
  } catch (e) {
    yield put({ type: 'audits/cloneAuditFailed', message: e })
  }
}

function * copyAuditAndDiscardOriginal(action) {
  try {
    const {auditInternalId} = action.payload;
    if (!auditInternalId) {
      throw new Error("Invalid internal audit Id")
    }
    yield call(cloneAndDiscardAudit,auditInternalId);

  }
  catch(e) {
    yield put({ type: 'audits/copyAuditAndDiscardOriginalFailed', message: e })

  }
}

function * markAuditAsRead (action) {
  try {
    const internalId = action.payload
    const storage = getStorage()
    yield all([
      call(
        storage.updateAudits,
        { InternalId: parseInt(internalId) },
        { IsUnread: false }
      ),
      put({
        type: 'audits/updateAudits',
        payload: {
          filter: { InternalId: parseInt(internalId) },
          updateModel: { IsUnread: false }
        }
      })
    ])
  } catch (e) {
    yield put({ type: 'audits/markAuditAsReadFailed', message: e })
  }
}

function * enrichAuditModel (audit) {
  const storage = getStorage()
  const tki = yield call(
    [storage, storage.getToolkitInstance],
    audit.ToolkitInstanceId
  )

  const [location] = yield call([storage, storage.getLocations], {
    LocationId: audit.LocationId
  })

  const sectionNotes = audit.SectionNotes?.map(p=> ({ Id: p.SectionId, Notes:p.SectionNotes}))

  return mapAuditToViewModel(
    audit,
    { Name: location.Facility.OrganisationName },
    location,       
    location.Facility,
    tki,
    sectionNotes
  )
}

function * openAudit (action) {
  try {
    const { audit } = action.payload

    if (audit.UpdatedExternally) {
      yield put({type:"audits/setErrorState",
          payload:{ "UPDATED_EXTERNALLY" :{AuditInternalId: audit.InternalId}
        }
      });
      return;
    }

    yield put({ type: 'audits/setLastAudit', audit })
    yield put({ type: 'CLEAR_CURRENT_AUDIT' })
    yield put({ type:"GO_TO_AUDIT", payload:{AuditInternalId: audit.InternalId}})    
  } catch (e) {
    yield put({ type: 'audits/openAuditFailed' })
  }
}

function * fetchSnapshotsFromServer () {
  try {
    const storage = getStorage()
    yield put({ type: 'snapshots/setSyncStatus', payload: true })
    const snapshotTkis = yield call(
      [storage, storage.getToolkitInstanceSummaries],
      'Snapshot'
    )
    const tkiModel = yield call(
      fetchSnapshots,
      snapshotTkis.map(p => p.Id)
    )

    const locationAgreements = []
    for (const [key, value] of Object.entries(tkiModel.LocationAgreements)) {
      value.forEach(tk => {
        locationAgreements.push({ LocationId: key, ToolkitInstanceId: tk })
      })
    }

    const facilities = tkiModel.Facilities.filter(p => !p.Archived).map(p => ({
      Id: p.Id,
      Name: p.Name,
      Address: p.Address,
      Locations: tkiModel.Locations.filter(
        l => l.FacilityId === p.Id && !l.Archived
      ).map(l => ({ Id: l.Id, Name: l.Name, Tags: l.Tags }))
    }))

    const rooms = tkiModel.Rooms;

    yield all([
      // call([storage, storage.upsertToolkitInstances],tkiModel.ToolkitInstances),
      call([storage, storage.upsertFacilities], facilities),
      call([storage, storage.upsertRooms], rooms),
      call([storage, storage.upsertLocationAgreements], locationAgreements),
      ...tkiModel.ToolkitInstances.map(p => call(saveToolkitInstance, p)),
      put({ type: 'snapshots/setSyncStatus', payload: false })
    ])

    yield all([
      put({
        type: 'snapshots/setLocationAgreements',
        payload: locationAgreements
      }),
      put({ type: 'snapshots/setToolkits', payload: tkiModel.ToolkitInstances }),
      put({ type: 'snapshots/setFacilities', payload: facilities })
    ])

  } catch (e) {
    yield put({ type: 'audits/fetchSnapshotsFromServerFailed', message: e })
  }
}

function * sync (action) {
  try {
    const { migrationChecked, isOnline } = yield select(
      state => state.appState
    )

    const { synchronizing } = yield select(getAuditsSelector)

    const { backgroundSync } = yield select(
      state => state.settings
    )

    if (synchronizing) {
      return;
    }

    if (!backgroundSync && action?.payload?.type==="background") {
      return;
    }
    
    if (!isOnline) {
      return;
    }

    // don't sync until data is migrated
    if (!migrationChecked) {
      return;
    }

    yield put({ type: 'audits/syncAllStatus', payload:true })
    yield all([      
      call(fetchSnapshotsFromServer), 
      call(syncAudits),
      call(pushDirtyAuditsToServer)
    ])
    yield put({ type: 'audits/syncAllStatus', payload:false })

  } catch (e) {
    yield all([
      put({ type: 'audits/syncFailed', message: e }),
      put({ type: 'audits/syncAllStatus',payload: false })
    ]);
  }
}

function * loadSnapshots (action) {
  const storage = getStorage()

  const [toolkits, facilities, locationAgreements] = yield all([
    call([storage, storage.getToolkitInstanceSummaries], 'Snapshot'),
    call([storage, storage.getFacilities]),
    call([storage, storage.getLocationAgreements])
  ])

  yield all([
    put({
      type: 'snapshots/setLocationAgreements',
      payload: locationAgreements
    }),
    put({ type: 'snapshots/setToolkits', payload: toolkits }),
    put({ type: 'snapshots/setFacilities', payload: facilities })
  ])
}

function * createAudit (action) {
  try {
    const storage = getStorage()
    const { LocationId, FacilityId, ToolkitInstanceId } = action.payload
    const isoNow = new Date().toISOString()
    const { OrganisationId, Id: AuditorId } = yield select(
      state => state.appState.userInfo
    )
    const [tkiSummary, facility] = yield all([
      call([storage, storage.getToolkitInstanceSummary], ToolkitInstanceId),
      call([storage, storage.getFacility], FacilityId)
    ])

    const location = facility.Locations.find(l => l.Id === LocationId)

    const auditModel = {
      AuditorId: AuditorId,
      OrganisationId: OrganisationId,
      AuditType: 'Snapshot',
      IsDirty: 1,
      Status: AuditStatus.ALLOCATED,
      Created: isoNow,
      CreatedTs: isoNow,
      Scheduled: isoNow,
      Started: isoNow,
      Version: 1,
      IsUnread: true,
      UpdatedTs: isoNow,
      SectionNotes: [],
      LocationId,
      LocationName: location.Name,
      Address : facility.Address,
      FacilityId,
      FacilityName: facility.Name,
      ToolkitId: tkiSummary.ToolkitId,
      ToolkitInstanceId: tkiSummary.Id
    }

    auditModel.InternalId = yield call([storage, storage.addAudit], auditModel)

    yield put({ type: 'audits/addAudit', payload: auditModel })
    yield put({
      type: 'GO_TO_AUDIT',
      payload: { AuditInternalId: auditModel.InternalId }
    })
  } catch (e) {
    yield put({ type: 'audits/createFailed', message: e })
  }
}

function * pushAuditToServer (audit) {
  try {
    const currentlyEditingAudit = yield select(state => state.auditEntry.audit)

   // if (audit.InternalId === currentlyEditingAudit.InternalId) {
   //   audit = currentlyEditingAudit;
   // }

    const storage = getStorage();
    const answers = yield call(
      [storage, storage.getAnswersForAudit],
      audit.InternalId
    )

    const auditPushRequest = {
      AuditId: audit.Id?audit.Id:"",
      UpdatedTs: audit.UpdatedTs,
      ToolkitId: audit.ToolkitId,
      ToolkitInstanceId: audit.ToolkitInstanceId,
      AccompaniedBy: audit.AccompaniedBy,
      Source: 'mobile',
      AdditionalInformation: audit.Notes,
      LocationId: audit.LocationId,
      SectionNotes: audit.SectionNotes?.filter(p=>!!p.Id),
      AnsweredQuestions: answers.map(a=>({
        NotAnswered: a.NotAnswsered,
        QuestionAnswers: a.QuestionAnswers,
        QuestionId: a.QuestionId,
        Success: a.Success
      })),
      Status: audit.Status,
      Scheduled: audit.Scheduled,
      Started: audit.Started,
      Version: audit.Version,
      RoomId: audit.RoomId,
      RoomReference: audit.RoomReference,
      RoomName: audit.RoomName      
    }

    const pushAuditResponse = yield call(pushAudit, auditPushRequest)

    pushAuditResponse.Audit.IsDirty = 0
    pushAuditResponse.Audit.InternalId = audit.InternalId;

    if (pushAuditResponse.Success) {

      if (pushAuditResponse.NewRoomModel) {
        yield call(saveRoomsToStore, [pushAuditResponse.NewRoomModel])
        pushAuditResponse.Audit.RoomReference = ''
        pushAuditResponse.Audit.RoomName = ''
        yield put({type:'auditEntry/updateAudit',payload: 
          {
            RoomId: pushAuditResponse.Audit.RoomId,
            RoomReference : '',
            RoomName:''
          }
        })
        
        yield put({type:'auditEntry/addRoom', payload:pushAuditResponse.NewRoomModel})
      }

      if (audit.Status === AuditStatus.COMPLETE) {
        yield call(removeAuditById, audit.InternalId)
      } else {
        yield call(updateAuditRecord, { payload: pushAuditResponse.Audit })

        if (currentlyEditingAudit && currentlyEditingAudit.InternalId === audit.InternalId) {
          yield put({type:"UPDATE_AUDIT_META",payload:{IsDirty:0, Id: pushAuditResponse.Audit.Id}})
        }

      }
    } else {
      if (pushAuditResponse.Modified) {
        yield call(setUpdatedExternallyStatusForAudits,[pushAuditResponse.Audit.Id])
      }

      if (pushAuditResponse.Recalled) {
        yield call(syncRecalledAudits, [pushAuditResponse.Audit.Id])
      }
    }    
  } catch (e) {
    yield put({ type: 'audits/pushAuditFailed', message: e })
  }
}



function * pushDirtyAuditsToServer (action) {
  try {
    yield put({ type: 'audits/setAuditSyncStatus', payload: true })
    yield put({ type: 'auditEntry/setSavingStatus', payload: true })
    
    const storage = getStorage()
   // const currentlyEditingAudit = yield select(state => state.auditEntry.audit)
    const dirtyAudits = yield call([storage, storage.getAudits], { IsDirty: 1 })
   
    const auditsToPush = dirtyAudits.filter(audit => {
      // only sync complete snapshots
      if (
        audit.AuditType === 'Snapshot' &&
        audit.Status < AuditStatus.COMPLETE
      ) {
        return false
      }

      // don't sync audit externally modified
      if (audit.UpdatedExternally) {
        return false
      }

      if (audit.Recalled) {
        return false;
      }

      /*if (currentlyEditingAudit && audit.Id === currentlyEditingAudit.Id) {
        if (
          action.payload &&
          !action.payload.includes(currentlyEditingAudit.InternalId)
        ) {
          return false
        }
      }*/

      return true
    })

    yield all(auditsToPush.map(a => call(pushAuditToServer, a)))
    yield all([
      call(countAudits), 
      put({ type: 'auditEntry/setSavingStatus', payload: false }),
       put({ type: 'audits/setAuditSyncStatus', payload: false })
    ])

  } catch (e) {
    yield put({ type: 'audits/pushAuditFailed', message: e })
  }
}

function* countAudits() {
  try {
    const audits = yield select(state=>state.audits.auditList);

    const unread = audits.filter(p=> p.Status === AuditStatus.ALLOCATED).length;
    const pending = audits.filter(p=>p.Status === AuditStatus.COMPLETE).length;
    const snapshots = audits.filter(p=> p.AuditType==="Snapshot").length;

    yield put({type: 'audits/setCount',payload: { unread,pending, snapshots}})
  }
  catch(e) {
    yield put({type: 'audits/countAuditsFailed'})
  }
}

function * auditSaga () {  
  yield takeLatest('SYNC', sync)
  yield takeLatest('SYNC_AUDITS', syncAudits)
  yield takeLatest('SYNC_SNAPSHOTS', fetchSnapshotsFromServer)
  yield takeLatest('GET_AUDITS', getAuditsWithToolkits)  
  yield takeLatest('PUSH_AUDITS', pushDirtyAuditsToServer)
  yield takeLatest('OPEN_AUDIT', openAudit)
  yield takeEvery('MARK_AUDIT_AS_READ', markAuditAsRead)
  yield takeEvery('GET_SNAPSHOTS', loadSnapshots)
  yield takeEvery('CREATE_AUDIT', createAudit)
  yield takeEvery('COPY_AUDIT_AND_DISCARD_ORIGINAL',copyAuditAndDiscardOriginal)
  yield takeEvery('DISCARD_AUDIT',discardAudit)
}

export default auditSaga
