import React, { useCallback } from 'react'
import styled from 'styled-components'
import AuditStatusPanel from './AuditStatusPanel'
import { AuditStatus } from '../../../util/constants'
import { Fade, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Section } from './Section'
import { useDispatch } from 'react-redux'
import { Skeleton } from '@mui/material';
import { AuditPageFilterPanel } from '../AuditListPage/AuditPageFilterPanel'

const useStyles = makeStyles(theme => ({   
    skeletonSection: {
      margin: theme.spacing(1),
      borderRadius: theme.spacing(0.3),
      height: 60,
      opacity: '0.3'
    },
    background: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(5)
    }
  }))

const SkeletonContainer = styled.div`
   {
    margin-top: 50px;
  }
`

const AuditPageBody = React.memo(
  ({ audit, toolkitInstance, answers, sections, filter, onFilterChange}) => {
      const classes = useStyles();
    const dispatch = useDispatch();

         const handleAutoCompleteSection = useCallback((value, sectionId, unansweredQuestions) => {        
        const update = unansweredQuestions.map(p => {
          return { fieldName: 'Compliancy', value: value, questionId: p.Id }
        })    
        dispatch({ type: 'UPDATE_AUDIT_ANSWERS', payload: update })
          
      }, [dispatch])
   
      const renderCompletePanel = () => {
      if (audit && audit.Status === AuditStatus.READY) {
        return <AuditStatusPanel audit={audit} />
      }

      return null
    }

    const renderSections = () => {
      return (
        <Paper elevation={0} className={classes.background}>
          {renderCompletePanel()}
          {sections.map(section => (
            <Section
              key={section.Id}
              section={section}
              auditId={parseInt(audit.id)}
              toolkitInstance={toolkitInstance}
              answers={answers}
              filter={filter}
              audit={audit}
              onRequestAutoCompleteSection={handleAutoCompleteSection}
            />
          ))}
        </Paper>
      )
    }

    const renderSkeleton = () => {
      return Array.from(new Array(16)).map((p, idx) => {
        return (
          <Skeleton
            key={'audit-skeleton' + idx}
            animation='wave'
            variant="rectangular"
            classes={{ root: classes.skeletonSection }}
          />
        );
      });
    }

    const renderAudit = () => {
      const loading = !audit || !toolkitInstance || !answers || !sections

      return (
        <>{loading}
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? '0ms' : '0ms'
            }}
            unmountOnExit
          >
            <SkeletonContainer>{renderSkeleton()}</SkeletonContainer>
          </Fade>

          {loading === false && renderSections()}
        </>
      )
    }

    return (
      <>
        <AuditPageFilterPanel
          onFilterChange={onFilterChange}
          {...filter}
        />

        {renderAudit()}
      </>
    )
  }
)

export {AuditPageBody}