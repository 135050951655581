import React, {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useDispatch } from 'react-redux'
import { resetPassword } from '../../../actions/appActions'
import Alert from '@mui/material/Alert';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const ForgotPasswordForm = () => {
  const dispatch = useDispatch()  
  const [username, setUsername] = useState('');  
  const [status, setStatus] = useState('');  
  
  const classes = useStyles();

  const submitted = (event) => {
    dispatch(resetPassword(username))
          .then( () => { setStatus({message:"Your password reset email has been sent to you", error:false}) })
          .catch( () => {setStatus({message:"An error occured resetting your password. Please contact support",error:true})});
    event.preventDefault();
  };

  const renderAction = (status) => {

    const retryButton = ( <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            >
            Try again
        </Button>);

    const resetButton = (<Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                >
                Reset Password
            </Button>);

    const backToLoginButton = (<Button
              href="/login"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                >
                Back to Sign In
            </Button>);

     const message = (<Alert variant="outlined" severity={status.error?"error":"success"}>{status.message}</Alert>)


     return (<>
        {status && message}
        {status && status.error && retryButton}
        {status && !status.error && backToLoginButton}
        {!status && resetButton}
     </>);      
  }
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form className={classes.form} noValidate onSubmit={submitted}>
          <TextField
            autoFocus
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={username}
            onChange={({target: {value}}) => setUsername(value)}
          />
       
    
         {renderAction(status)}
          <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2" underline="hover">
                Back to Sign In
              </Link>
            </Grid>
            <Grid item>
             
            </Grid>
          </Grid>
        </form>
      </div>
    
    </Container>
  );
};