import WebStorage from "./web";
import NativeStorage from "./native";

/**
 * Enum for audit types.
 * @readonly
 * @enum {string}
 */
export const AUDIT_TYPES = {
  AUDIT: 'Audit',
  SNAPSHOT: 'Snapshot',
}


/**
 * The storage must follow this diagram
 * https://lucid.app/lucidchart/invitations/accept/inv_4099f689-b6d6-49a2-bae2-0edbd6d73c4f?viewport_loc=200%2C866%2C1965%2C1149%2C0_0
 * */

let singleton;

/**
 * @example
 * import getStorage, {AUDIT_TYPES} from 'path/to/this/file'
 * const storage = getStorage();
 * const audits = await storage.getAudits({Type: AUDIT_TYPES.AUDIT});
 * @returns {NativeStorage | WebStorage}
 * */
export default function getStorage() {
  const isWeb = true;
  if (isWeb) {
    return singleton || (singleton = new WebStorage());
  }

  return singleton || (singleton = new NativeStorage());
}


















