import React, {useState, useEffect} from 'react';
import marked from 'marked';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux'
import { setAuthenticatedUser, setDisclaimer } from '../../../actions/appActions'
import { useLoginError, useDisclaimer, useUser } from "../../../hooks/useUserInfo";
import Box from '@mui/material/Box';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/" underline="hover">
        IPC Management Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Disclaimer = () => {
  const dispatch = useDispatch();
  const markdown = useDisclaimer();
  const [open, setOpen] = useState(true);
  const user = useUser();

  const onAgreeClick = () => {
    setOpen(false);
    dispatch(setAuthenticatedUser(true));
  }

  const onDisagreeClick = () => {
    setOpen(false);
    dispatch(setDisclaimer(null));
  }
  useEffect(() => {
    localStorage.setItem('icat-user', JSON.stringify(user));
  });

  return (
    <div>
      <Dialog
        onBackdropClick="false"
        open={open||false}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="disclaimer" dangerouslySetInnerHTML={{__html:marked(markdown)}} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onDisagreeClick()} color="primary">
            Disagree
          </Button>
          <Button onClick={() => onAgreeClick()} color="primary" autoFocus>
            I Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const LoginForm = () => {
  const dispatch = useDispatch()  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const error = useLoginError();

  const classes = useStyles();

  const submitted = (event) => {
    dispatch({type:"LOGIN",payload:{username, password}})
    event.preventDefault();
  };

  return (
    <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '100vh' }}
  >
    <Grid item xs={9}>


      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={submitted} autoComplete="off">
          <TextField
            autoFocus
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="e-m-a-i-l"
            label="Email Address"
            name="e-m-a-i-l"
            autoComplete="e-m-a-i-l"
            value={username}
            onChange={({target: {value}}) => setUsername(value)}
            inputProps={{
              autoComplete: 'e-m-a-i-l',
            }}
          />
          <TextField           
            margin="normal"
            required
            fullWidth
            name="new-password"
            id="new-password"
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            autoComplete="new-password"
            onChange={({target: {value}}) => setPassword(value)}
            inputProps={{
              autoComplete: 'new-password',
            }}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          {error && <div>{error}</div>}
          <Grid container>
            <Grid item xs>
              <Link href="/login/forgot" variant="body2" underline="hover">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>

            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>

    </Grid>

  </Grid>
  );
};