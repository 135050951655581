import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import getStorage from '../storage'
import { buildScope, interpolate } from '../util/interpolation'
import _ from 'lodash'
import { AuditStatus } from '../util/constants'

function * fetchAudit (action) {
  const storage = getStorage()
  try {
    const auditInternalId = parseInt(action.payload)
    const [audit, answers] = yield all([
      call([storage, storage.getAudit], auditInternalId),
      call([storage, storage.getAnswersForAudit], auditInternalId)
    ])

    const [sections, toolkitInstance, rooms] = yield all([
      call(
        [storage, storage.getSectionsForToolkitInstance],
        audit.ToolkitInstanceId
      ),
      call([storage, storage.getToolkitInstance], audit.ToolkitInstanceId),
      call([storage, storage.getRooms], audit.LocationId)
    ])   

    yield put({
      type: 'auditEntry/auditFetched',
      payload: { audit, sections, answers, toolkitInstance, rooms }
    })

    //  if (audit.ResultSummary==null || audit.IsDirty) {
    yield call(updateAuditMeta, { IsUnread: false })
    //  }
  } catch (e) {
    yield put({ type: 'auditEntry/auditFetchedFailed', message: e })
  }
}

function * clearCurrentAudit () {
  yield put({
    type: 'auditEntry/clear'
  })
}

function * setSectionState (action) {
  const { id, state } = action.payload
  yield put({ type: 'auditEntry/setSectionState', payload: { id, state } })
}

function * updateAnswers (action) {
  try {
    if (!action.payload || !action.payload.length) {
      return
    }
    yield put({ type: 'auditEntry/setValidationMessages', payload: [] })
    yield all(action.payload.map(update => call(updateAnswer, update)))
  } catch (e) {
    yield put({ type: 'auditEntry/updateAnswersFailed', message: e })
  }
}

function * getCalculatedProperties (questionAnswer, currentQuestion) {
  try {
    const notApplicableExpression = '{{Compliancy}}==2'
    const scope = buildScope(questionAnswer.QuestionAnswers)
    let success
    let notApplicable

    notApplicable = interpolate(scope, notApplicableExpression)
    if (!notApplicable) {     
      success = interpolate(scope, currentQuestion.SuccessExpression)     
    }
    yield
    return { ...questionAnswer, Success: success, NotAnswsered: notApplicable }
  } catch (e) {
    return {}
  }
}

function * validationAnswer (question, questionAnswer) {
  try {
    if (!question || !questionAnswer) {
      return
    }

    const actions = question.CompletedStates.map(state => {
      if (
        !interpolate(
          buildScope(questionAnswer.QuestionAnswers),
          state.Expression,
          true
        )
      ) {
        return put({
          type: 'auditEntry/addValidationMessage',
          payload: state.Details
        })
      }

      return null
    }).filter(p => !!p)

    yield all(actions)
    return actions && actions.length === 0
  } catch (e) {
    yield put({ type: 'auditEntry/validationAnswerFailed', message: e })
    return false
  }
}

function * updateSectionNotes (action) {
  const storage = getStorage()
  try {
    const { sectionId, notes } = action.payload
    const { audit } = yield select(state => state.auditEntry)
    let newSectionNotes

    if (!audit.SectionNotes) {
      newSectionNotes = [{ Id: sectionId, Notes: notes }]
    } else {
      newSectionNotes = _.cloneDeep(audit.SectionNotes)
      const sectionNotesIndex = newSectionNotes.findIndex(
        p => p.SectionId === sectionId
      )
      if (sectionNotesIndex === -1) {
        newSectionNotes.push({ Id: sectionId, Notes: notes })
      } else {
        newSectionNotes[sectionNotesIndex].Notes = notes
      }
    }

    yield put({
      type: 'auditEntry/updateSectionNotes',
      payload: newSectionNotes
    })

    yield call(updateAuditInternal, { InternalId: audit.InternalId, SectionNotes: newSectionNotes, IsDirty:1})

    call([storage, storage.upsertAudit], {
      InternalId: audit.InternalId,
      IsDirty: 1,
      SectionNotes: newSectionNotes
    })
  } catch (e) {
    yield put({ type: 'auditEntry/updateSectionNotesFailed', message: e })
  }
}

function * updateAuditMeta (meta) {
  try {
    const { audit, answers, toolkitInstance } = yield select(
      state => state.auditEntry
    )
    let status = audit.Status

    const answeredQuestions = answers.filter(p=> p.NotAnswsered || p.Success!==null);

    if (answeredQuestions.length === toolkitInstance.Questions.length) {
      status = AuditStatus.READY
    } else if (answeredQuestions.length) {
      status = AuditStatus.IN_PROGRESS
    } else {
      status = AuditStatus.CHECKED_OUT
    }

    if (audit.Status>= AuditStatus.COMPLETE) {
      status = audit.Status
    }

    const totalSuccess = answeredQuestions.filter(a => a.Success === true).length
    const resultSummary = {
      TotalAnswered: answeredQuestions.length,
      TotalNotApplicable: answeredQuestions.filter(a => a.NotAnswsered === true).length,
      TotalSuccess: totalSuccess,
      SuccessRatio:
        totalSuccess === 0 ? 0 : (totalSuccess / answers.length) * 100
    }

    yield call(updateAuditInternal, {
      InternalId: audit.InternalId,
      Status: status,
      ResultSummary: resultSummary,
      ...meta
    })
  } catch (e) {
    yield put({ type: 'auditEntry/updateAuditMetaFailed', message: e })
  }
}

function * updateAuditInternal (update) {
  const storage = getStorage()

  yield all([
    call([storage, storage.upsertAudit], update),
    put({
      type: 'audits/updateAudits',
      payload: {
        filter: { InternalId: update.InternalId },
        updateModel: update
      }
    }),
    put({
      type: 'auditEntry/updateAudit',
      payload: update
    })
  ])
}

function * updateAudit (action) {
  try {
    yield call(updateAuditInternal, action.payload)
  } catch (e) {
    yield put({ type: 'auditEntry/updateAuditFailed', message: e })
  }
}

function * updateAuditStatus (action) {
  const { InternalId, Status } = action.payload
  yield call(updateAuditInternal, { InternalId, Status, IsDirty:1 })
}

function * updateAuditStatusAndPush (action) {
  const { InternalId } = action.payload
  yield call(updateAuditStatus, action)
  yield put({type:"PUSH_AUDITS",payload:[InternalId]})
}


function * updateAnswer (update) {
  try {
    const storage = getStorage()
    const { audit, answers, toolkitInstance } = yield select(
      state => state.auditEntry
    )
    const currentQuestion = toolkitInstance.Questions.find(
      q => q.Id === update.questionId
    )

    if (!currentQuestion) {
      throw new Error('Question not found in audit')
    }

    let finalQuestionAnswer = null

    const questionAnswerIndex = answers.findIndex(
      a => a.QuestionId === update.questionId
    )
    if (questionAnswerIndex === -1) {
      const questionAnswer = {
        AuditInternalId: parseInt(audit.InternalId),
        QuestionId: update.questionId,
        QuestionAnswers: [
          {
            FieldName: update.fieldName,
            Value: update.value,
            FieldType: update.fieldType
          }
        ]
      }

      finalQuestionAnswer = yield call(
        getCalculatedProperties,
        questionAnswer,
        currentQuestion
      )

      yield all([
        call(validationAnswer, currentQuestion, finalQuestionAnswer),
        put({
          type: 'auditEntry/addQuestionAnswer',
          payload: finalQuestionAnswer
        })
      ])
    } else {
      const questionAnswer = _.cloneDeep(answers[questionAnswerIndex])
      const fieldAnswerIndex = questionAnswer.QuestionAnswers.findIndex(
        qa => qa.FieldName === update.fieldName
      )
      if (fieldAnswerIndex === -1) {
        questionAnswer.QuestionAnswers.push({
          FieldName: update.fieldName,
          Value: update.value,
          FieldType: update.fieldType
        })
      } else {
        questionAnswer.QuestionAnswers[fieldAnswerIndex] = {
          FieldName: update.fieldName,
          Value: update.value,
          FieldType: update.fieldType
        }
      }

      finalQuestionAnswer = yield call(
        getCalculatedProperties,
        questionAnswer,
        currentQuestion
      )
      yield all([
        call(validationAnswer, currentQuestion, finalQuestionAnswer),
        put({
          type: 'auditEntry/updateQuestionAnswerValue',
          payload: finalQuestionAnswer
        })
      ])
    }

    const { validationMessages } = yield select(state => state.auditEntry)

    // dont commit an invalid answer to database
    if (finalQuestionAnswer && validationMessages.length === 0) {
      yield all([
        call([storage, storage.setAuditQuestionAnswer], finalQuestionAnswer),
        call(updateAuditMeta, { IsDirty: 1 })
      ])
    }
  } catch (e) {
    yield put({ type: 'auditEntry/updateAnswerFailed', message: e })
  }
}

function * touchAudit (action) {
  try {
    const { InternalId } = action.payload
    const storage = getStorage()

    call([storage, storage.upsertAudit], {
      InternalId: InternalId,
      IsDirty: 1
    })
    yield call(updateAuditInternal, { InternalId, IsDirty: 1 })
  } catch (e) {
    yield put({ type: 'audits/touchAuditFailed', message: e })
  }
}

function * setAuditMeta (action) {
  yield call(updateAuditMeta, action.payload)
}

function * auditRecordSaga () {
  yield takeLatest('GET_AUDIT', fetchAudit)
  yield takeLatest('CLEAR_CURRENT_AUDIT', clearCurrentAudit)
  yield takeEvery('UPDATE_AUDIT_ANSWERS', updateAnswers)
  yield takeEvery('UPDATE_SECTION_NOTES', updateSectionNotes)
  yield takeEvery('UPDATE_AUDIT', updateAudit)
  yield takeEvery('UPDATE_AUDIT_META', setAuditMeta)
  yield takeEvery('SET_SECTION_STATE', setSectionState)
  yield takeEvery('UPDATE_AUDIT_STATUS', updateAuditStatus)
  yield takeEvery('UPDATE_AUDIT_STATUS_AND_PUSH', updateAuditStatusAndPush)
  yield takeLatest('TOUCH_AUDIT', touchAudit)
}

export default auditRecordSaga
