import { useState } from "react";
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { ListItemSecondaryAction } from "@mui/material";

export const DeleteDataStoreItem = ({onDelete}) => {

    const [confirming, setConfirming] = useState(false);

    const itemText = confirming ? "Are you sure?" : "Delete Data Store";
    const secondary = true;

    const handleDelete = () => {
        if (confirming) {
            setConfirming(false);
            onDelete && onDelete()
        }
        else {
            setConfirming(true);
        }
    }

    return (
        <ListItem onClick={confirming?null:handleDelete}>    
        <ListItemText
          primary={itemText}
          secondary={secondary ? 'Deleting the data store will remove all local information, including audits not yet completed.' : null}
        />
        {confirming && <ListItemSecondaryAction>
          <IconButton
              color={"secondary"}
              autofocus
              edge="end"
              aria-label="delete"
              onClick={handleDelete}
              size="large">
            <DeleteIcon />
          </IconButton></ListItemSecondaryAction>}    
      </ListItem>
    );
}