import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { AuditStatus } from '../../../util/constants';
import { Grid } from '@mui/material';

const useStyles = makeStyles(theme=>({
  root: {
    minWidth: 275,
    margin: theme.spacing(1)
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginTop: 12,
  },
  cardContent:{
    paddingBottom: theme.spacing(3)
  }
}));

export default function AuditStatusPanel({audit}) {
  const classes = useStyles();
  const dispatch = useDispatch()

  const handleCompleteAudit = ()=> {
    dispatch([
        {type:"UPDATE_AUDIT_STATUS",payload:{InternalId: audit.InternalId, Status: AuditStatus.COMPLETE}},
        {type:"GO_TO_OUTBOX"},
    ])
  }

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.cardContent}>       
        <Grid container direction="row">
        <Grid item xs={12}>
          <Typography variant="subtitle1" component="h2">
          All questions have been completed
        </Typography>
        <Typography className={classes.pos} variant="subtitle2" >
        You can review and edit if needed then select the
COMPLETE button to send the audit back. 
        </Typography>
        <Typography  className={classes.pos} variant="subtitle2" color="textSecondary">
        You will be able to see the audit in the Outbox
until it has synchronised. You will need to be connected to the internet to synchronise.
        </Typography>
       
        </Grid>
        <Grid container justifyContent="flex-end" alignContent="center" >
       
        <Button style={{backgroundColor:'green', color:'white'}} variant="contained" size="small" onClick={handleCompleteAudit}>Complete</Button>
    
        </Grid>
        </Grid>
      </CardContent>
   
    </Card>
  );
}
