import Dexie from 'dexie'
import BaseStorage from './base'

export default class WebStorage extends BaseStorage {
  constructor () {
    super()
    /**
     * @property db
     * @private
     * */
    this.db = new Dexie('icat-mobile-web')

    this.db.version(10).stores({
      audits: '++InternalId,Id,ToolkitInstanceId,Status,IsDirty',
      toolkits: '&Id,ToolkitId',
      rooms: '&Id,LocationId',
      toolkitSummaries: '&Id,ToolkitType',
      facilities: '&Id',
      locationAgreements:
        '[LocationId+ToolkitInstanceId],LocationId,ToolkitInstanceId',
      answers: '[AuditInternalId+QuestionId],AuditInternalId',
      sections:
        '[ToolkitInstanceId+SectionId],ToolkitInstanceId,SectionId,Index'
    })
    this.db.open()
  }

  getDb() {
    return this.db;
  }

  async getAudit (id) {
    return this.db.audits.get(id)
  }

  async getAudits (filterModel) {
    let query = this.db.audits

    if (filterModel && filterModel.InternalId) {
      return query.get(filterModel.InternalId)
    }

    if (filterModel && filterModel.Type) {
      query = query.where('Type').equals(filterModel.Type)
    }

    if (filterModel && filterModel.Id) {
      query = query.where('Id').equals(filterModel.Id)
    }

    if (filterModel && filterModel.IsDirty) {
      query = query.where('IsDirty').equals(1)
    }

    /** @type {AuditViewModel[]} */
    const audits = await query.toArray()

    return audits
  }

  async getAuditKeys () {
    if ((await this.db.audits.count())===0) {
      return [];
    }

     return await this.db.audits.orderBy('Id').uniqueKeys().toArray();
  }

  async getAuditAnswers (auditId) {
    /** @type {AnswerViewModel[]} */
    const answers = await this.db.answers
      .where('AuditId')
      .equals(auditId)
      .toArray()
    return answers
  }

  async getLocations (filterModel) {
    /** @type {LocationViewModel[]} */
    let query = this.db.locations

    if (filterModel && filterModel.LocationId) {
      return [await this.db.locations.get(filterModel.LocationId)] // array to maintain contract
    }

    const locations = await query.toArray()
    return locations
  }

  async getRooms(locationId) {
    return await this.db.rooms.where('LocationId').equals(locationId).toArray()
  }

  async getToolkitInstance (id) {
    const result = await this.db.toolkits
      .where('Id')
      .equals(id)
      .toArray()
    return result[0]
  }

  async getToolkitInstanceSummary (id) {
    return await this.db.toolkitSummaries.get(id)
  }

  async getAnswersForAudit (auditInternalId) {
    return await this.db.answers
      .where('AuditInternalId')
      .equals(auditInternalId)
      .toArray()
  }

  async getAnswersByQuestionIds (questionIds) {
    return await this.db.answers
      .where('QuestionId')
      .anyOf(questionIds)
      .toArray()
  }

  async getToolkitInstanceKeys () {
    if ((await this.db.toolkits.count()) === 0) {
      return []
    }
    return await this.db.toolkits.orderBy('Id').uniqueKeys()
  }

  async getToolkitInstanceSummaries (type) {
    if (!type) {
      return this.db.toolkitSummaries.toArray()
    }

    return this.db.toolkitSummaries
      .where('ToolkitType')
      .equals(type)
      .toArray()
  }

  async getToolkitInstances (type) {
    let query = this.db.toolkits
    if (type) {
      query = query.where('Type').equals(type)
    }
    /** @type {ToolkitInstance[]} */
    const toolkits = query.toArray()

    return toolkits
  }

  async setAuditQuestionAnswer (questionAnswer) {
    // legacy backward support
    //const notApplicableExpression = '{{Compliancy}}==2'

    /*const answerRecord = { 
      AuditInternalId : auditInternalId,
      QuestionId: questionId,
      ...field,
      Value: value      
    }    

    if (questionAnswers && currentQuestion) {
      const scope = buildScope(questionAnswers)       
      answerRecord.success = interpolate(scope, currentQuestion.SuccessExpression)
      answerRecord.notApplicable = interpolate(scope, currentQuestion.NotApplicableExpression || notApplicableExpression)
    }*/

    return await this.db.answers.put(questionAnswer)
  }

  async writeAuditQuestionAnswer (auditInternalId, questionAnswer) {
    const answerRecord = {
      AuditInternalId: auditInternalId,
      ...questionAnswer
    }

    return await this.db.answers.put(answerRecord)
  }

  async deleteAuditByAuditId (auditId) {
    return this.db.audits.where({ Id: auditId }).delete()
  }

  async deleteAudit (internalId) {
    return this.db.audits.delete(internalId)
  }

  async updateAudits (queryModel, auditUpdateModel) {
    if (queryModel.InternalId) {
      return this.db.audits.update(queryModel.InternalId, auditUpdateModel)
    }

    this.db.transaction('rw', this.db.audits, async () => {
      return this.db.audits.where(queryModel).modify(auditUpdateModel)
    })
  }

  async upsertAudit (audit) {
    let currentAudit

    // cache, and clear answers, we don't want them in the indexeddb
    const answeredQuestions = audit.AnsweredQuestions && [
      ...audit.AnsweredQuestions
    ]
    audit.AnsweredQuestions = null

    if (audit.InternalId) {
      currentAudit = await this.db.audits.get(audit.InternalId)
    } else if (audit.Id) {
      currentAudit = await this.db.audits
        .where('Id')
        .equals(audit.Id)
        .first()
    }

    if (currentAudit) {
      audit = { ...currentAudit, ...audit }
      await this.db.audits.update(currentAudit.InternalId, audit)
    } else {
      await this.db.audits.add(audit)
    }

    if (answeredQuestions) {
      let writeModel = answeredQuestions.map(answeredQuestion => {
        return { ...answeredQuestion, AuditInternalId: audit.InternalId }
      })

      await Promise.all(
        writeModel.map(answer =>
          this.writeAuditQuestionAnswer(answer.AuditInternalId, answer)
        )
      )
      return Promise.resolve(audit)
    }

    return await Promise.resolve(audit)
  }

  async upsertLocation (location) {
    return await this.db.locations.put(location)
  }

  async upsertRoom (room) {
    return await this.db.rooms.put(room)
  }

  async upsertToolkitInstance (toolkitInstance) {
    return await this.db.toolkits.put(toolkitInstance)
  }

  async upsertToolkitInstanceSummary (toolkitInstanceSummary) {
    return await this.db.toolkitSummaries.put(toolkitInstanceSummary)
  }

  async getSectionsForToolkitInstance (toolkitInstanceId) {
    return await this.db.sections
      .where({ ToolkitInstanceId: toolkitInstanceId })
      .sortBy('Index') //.toArray();
  }

  async upsertFacilities (facilities) {
    return await this.db.facilities.bulkPut(facilities)
  }

  async upsertRooms(rooms) {
    return await this.db.rooms.bulkPut(rooms);
  }

  async getFacilities () {
    return await this.db.facilities.toArray()
  }

  async getFacility (id) {
    return await this.db.facilities.get(id)
  }

  async getLocationAgreements () {
    return await this.db.locationAgreements.toArray()
  }

  async upsertToolkitInstances (toolkitInstances) {
    const tkiSummaries = toolkitInstances.map(tki => ({
      Id: tki.Id,
      InstanceName: tki.InstanceName,
      QuestionCount: tki.Questions.length,
      ToolkitType: tki.ToolkitType || 'Audit'
    }))

    return Promise.all([
      this.db.toolkits.bulkPut(toolkitInstances),
      this.db.toolkitSummaries.bulkPut(tkiSummaries)
    ])
  }

  async upsertLocationAgreements (locationAgreements) {
    return await this.db.locationAgreements.bulkPut(locationAgreements)
  }

  async upsertSection (section) {
    return await this.db.sections.put(section)
    /*const existingSection = await this.db.sections.get(section.SectionId);
    if (!existingSection) {
      const sectionEntity = {...section, ...{ToolkitInstanceIds: [section.SectionId]}};
      delete sectionEntity.ToolkitInstanceId;
      return this.db.sections.add(sectionEntity);
    }
    else {
      existingSection.ToolkitInstanceIds.push(section.ToolkitInstanceId);
      this.db.sections.update(section.SectionId, existingSection);
    }*/
  }

  async clearDatabase () {
    await Promise.all(this.db.tables.map(t => this.db[t.name].clear()))
  }

  async addAudit (audit) {
    return await this.db.audits.add(audit)
  }
}
