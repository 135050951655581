import { Paper, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    panel: {
      padding:theme.spacing(2)
    },
   
  }))

export const NoAuditsAssigned = () => {
    const classes = useStyles();
    return (
        <Paper elevation={0} className={classes.panel}>
       
        <Typography align="center" variant="subtitle2" color="secondary" component="div">
            No audits are currently assigned to you. 
          </Typography>
        </Paper>
    )
}