import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  answers: null,
  sections: null,
  audit: null,
  rooms: [],
  toolkitInstance: null,
  validationMessages: [],
  sectionState: {},
  saving:false
}

const auditEntryReducer = createReducer(initialState, {
  'auditEntry/clear': (state, action) =>  state = initialState,
  'auditEntry/setSavingStatus': (state, action) =>   {state.saving=action.payload},
  'auditEntry/auditFetched': (state, action) =>  state = {...state, ...action.payload},
  'auditEntry/setValidationMessages': (state,action) => { state.validationMessages =[...action.payload] },
  'auditEntry/setAnswers': (state, action) => { state.answers = [...action.payload] },
  'auditEntry/addRoom': (state, action) => { state.rooms.push(action.payload);},
  'auditEntry/addQuestionAnswer': (state, action) => { state.answers.push(action.payload) },
  'auditEntry/updateQuestionAnswerValue': (state, action) => { updateQuestionAnswerValue(state.answers, action.payload)},
  'auditEntry/updateSectionNotes': (state,action) => { state.audit.SectionNotes = [...action.payload]; state.audit.IsDirty=1 },
  'auditEntry/addValidationMessage': (state, action) => { state.validationMessages.push(action.payload); },
  'auditEntry/updateAudit': (state, action) => { state.audit && updateAudit(state.audit, action.payload) },
  'auditEntry/setSectionState': (state,action) => { updateSectionState(state.sectionState, action.payload)}
})

export default auditEntryReducer;

function updateQuestionAnswerValue (answers, update) {
  const questionAnswer = answers.find(a=>a.QuestionId === update.QuestionId);
  Object.entries(update).forEach(([k, v]) => {
    questionAnswer[k] = v;
  }) 
}

function updateAudit(audit, update) {  
  Object.entries(update).forEach(([k, v]) => {
    audit[k] = v;
  }) 
}

function updateSectionState(sectionState, update) {
  if (!sectionState[update.id]) {
    sectionState[update.id] = {}
  }
  Object.entries(update.state).forEach(([k, v]) => {
    sectionState[update.id][k] = v;
  })   
}
