import { useMemo } from 'react'
import { CheckBoxField } from './CheckBoxField'
import { RadioField } from './RadioField'
import { TextAreaField } from './TextAreaField'
import styled from 'styled-components'
import { Box } from '@mui/material'
import { buildScope, interpolate } from '../../../../util/interpolation'

const Question = styled(Box)`
   {
    margin-top: 12px;
  }
`

export const QuestionField = ({ field, question, answers, onChange }) => {
  const answer = useMemo(() => {
    if (!answers) {
      return null
    }

    return answers.QuestionAnswers.find(p => p.FieldName === field.Name)
  }, [answers, field])

  const getAnswers = () => {
    if (!answers || !answers.QuestionAnswers) {
      return [];
    }

    return answers.QuestionAnswers;
  }

  const renderField = () => {
    
    const props = {
      value: answer && answer.Value,
      onChange: onChange,
      questionId: question.Id,
      field: field,
      visible: field.VisibleExpression
        ? interpolate(
            buildScope(getAnswers()),
            field.VisibleExpression,
            false
          )
        : true,
      enabled: field.EnabledExpression
        ? interpolate(
            buildScope(getAnswers()),
            field.EnabledExpression,
            false
          ) || false
        : true
    }

    // if (this.auditStore.isEntryDisabled) {
    //   props.enabled = false;
    // }

    if (props.visible === false) {
      return null
    }

   

    switch (field.Type) {
      case 'RadioField': {
       return <RadioField {...props} />
      }
      case 'CheckboxField': {
        props.value = props.value == null ? [] : props.value
        return <CheckBoxField disabled={props.enabled === false} {...props} />
      }
      case 'TextArea': {
         return <TextAreaField disabled={props.enabled === false} {...props} />
      }
      default:
        return null
    }
  }

  return <Question>{renderField()}</Question>
}
