import React, { useEffect } from 'react'
import { PrimaryAppBar } from '../../shared/AppBar'
import { AuditingDrawer } from '../../shared/AuditingDrawer'
import SnapshotToolkits from './SnapshotToolkits'
import { useSelector, useDispatch } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { SnapshotActionsBar } from '../../shared/AppBar/SnapshotActionsBar'

export const SnapshotPage = () => {
 
  const [drawerOpen, setdrawerOpen] = React.useState(null)
  const unopenedCount = useSelector(store => store.audits.unopenedCount)
  const dispatch = useDispatch()
  
  const snapshotsToolkits = useSelector(store => store.snapshots.toolkits)

  useEffect(() => {
    if (!snapshotsToolkits) {
      dispatch({ type: 'GET_SNAPSHOTS' })
    }
  }, [dispatch, snapshotsToolkits])

  return (
    <>
      <PrimaryAppBar
        title='Snapshots'
        drawerOpen={drawerOpen}
        onDrawerChange={open => setdrawerOpen(open)}
        count={unopenedCount}
        actions={<SnapshotActionsBar/>}
      />
      <AuditingDrawer
        open={drawerOpen || false}
        handleDrawerClose={() => setdrawerOpen(false)}
        handleDrawerOpen={() => setdrawerOpen(true)}
      />
      <Switch>
        <Route exact path='/snapshots'>
          <SnapshotToolkits/>
        </Route>
      </Switch>
    </>
  )
}
