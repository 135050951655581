import {
    useLocation,    
    Redirect    
  } from "react-router-dom";

import { useUser } from '../../hooks/useUserInfo'

export const Redirector = () => {

    let location = useLocation();
    let user = useUser();
      
     if (!user.isAuthenticated
        && !location.pathname.startsWith("/login")) {
        return <Redirect to={'/login'} />
      }
      else {
        if (user.isAuthenticated
          &&location.pathname==="/login") {
          return <Redirect to={'/audits'} />
        }
        if (location.pathname==="/") {
          return <Redirect to={'/audits'}/>
        }
    }  
    
    return null;
}
  