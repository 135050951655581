import { all, fork } from "@redux-saga/core/effects";
import applicationSaga from "./applicationSaga";
import auditRecordSaga from "./auditRecordSaga";
import auditSaga from "./auditSaga";
import migrationSaga from "./migrationSaga";
import navigationSaga from "./navigationSaga";
import questionSaga from "./questionSaga";
import settingsSaga from "./settingsSaga";

function* rootSaga () {
    yield all([
        fork(auditRecordSaga),
        fork(auditSaga),
        fork(applicationSaga),
        fork(questionSaga),
        fork(settingsSaga),
        fork(navigationSaga),
        fork(migrationSaga),
    ]);
}

export default rootSaga;