import React, { useEffect } from 'react'
import { CheckBoxItem } from './CheckBoxItem'
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: '1.1rem',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  disabled: {
    color: theme.palette.text.disabled
  }
}))

export const CheckBoxField = ({
  value,
  disabled,
  field,
  onChange,
  questionId
}) => {
  const [checkedItems, setCheckedItems] = React.useState(value)
  
  const classes = useStyles()

  useEffect(()=> {
    setCheckedItems(value)
  },[value])


  const handleItemChecked = (item, checked) => {
    checked ? processChecked(item) : processUnchecked(item)
  }

  const handleOnChange = items => {
    
    if (!onChange) {
      return null
    }

    onChange(field, items)
  }

  const processChecked = item => {
    if (checkedItems && checkedItems.includes(item.Value)) {
      return null
    }

    let union

    if (!checkedItems) {
      union = [item.Value]
    } else {
      union = [...checkedItems, item.Value]
    }

    setCheckedItems(union)

    handleOnChange(union)
  }

  const processUnchecked = item => {
    if (checkedItems && !checkedItems.includes(item.Value)) {
      return null
    }

    const index = checkedItems.findIndex(fi => fi === item.Value)

    if (index === -1) {
      return null
    }

    const newCheckedItems = [
      ...checkedItems.slice(0, index),
      ...checkedItems.slice(index + 1)
    ]

    setCheckedItems(newCheckedItems)

    handleOnChange(newCheckedItems)
  }

  const renderOptions = () => {
    if (!field) {
      return null
    }

    return field.Items.map((item,idx) => {
      const selected = checkedItems && checkedItems.includes(item.Value)

      return (
        <CheckBoxItem
          key={idx + 'checkbox'}
          disabled={disabled}
          checked={selected}
          onItemChecked={handleItemChecked}
          item={item}
        />
      )
    })
  }

  if (!field) {
    return null
  }

  return (
    <>
      <Typography variant='h6' className={classes.label}>
        {field.Label}
      </Typography>

      {renderOptions()}
    </>
  )
}
