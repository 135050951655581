import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  ListItem,
  List,
  ListItemText,
  Collapse,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

const useStyles = makeStyles(theme => ({
  dialogAppBar: {
    position: 'relative'
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  title: {
    paddingBottom: 0
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}))

const LocationItem = ({ facilityId, location, onSelected }) => {
  const classes = useStyles()

  const handleItemSelected = () => {
    onSelected && onSelected(facilityId, location.Id)
  }

  return (
    <ListItem
      key={location.Id}
    
      button
      onClick={handleItemSelected}
      className={classes.nested}
    >
      <ListItemText primary={location.Name} />
    </ListItem>
  )
}

const FacilityItem = ({ facility, onLocationSelected }) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={facility.Name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {facility.Locations.map(p => (
            <LocationItem
              location={p}
              facilityId={facility.Id}
              onSelected={onLocationSelected}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}

export const SelectLocationDialog = ({ onClose, toolkitInstance }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const facilities = useSelector(state => state.snapshots.facilities)

  const handleLocationSelected = (facilityId, locationId) => {
    //onClose && onClose()
    // setTimeout(
    //    () =>
    dispatch([
      { type: 'auditEntry/clear' },
      {
        type: 'CREATE_AUDIT',
        payload: {
          ToolkitInstanceId: toolkitInstance.Id,
          LocationId: locationId,
          FacilityId: facilityId
        }
      }
    ]) //,
    //    0
    //  )
  }

  return (
    <Dialog
      fullWidth={'sm'}
      // maxWidth={"sm"}
      open={true}

      //  TransitionComponent={Transition}
    >
      <DialogTitle className={classes.title} id='form-dialog-title'>
        Select Location
      </DialogTitle>
      <DialogContent>
        <List dense>
          {facilities.map(p => (
            <FacilityItem
              onLocationSelected={handleLocationSelected}
              facility={p}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
