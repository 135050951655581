import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import {   
    TextField,
    Toolbar,    
  } from '@mui/material'

const useStyles = makeStyles(theme => ({   
    textField: {
      minHeight: 'calc(100vh - 100px)',
      alignItems:'start'
    },   
  }))

const SectionNotes = React.memo(({value, onChange}) => {
   const classes = useStyles();
    return <>
     <Toolbar/>
          <TextField
            autoFocus InputProps={{className:classes.textField}}
            margin="dense"
            id="section-notes"
            value={value}
            multiline            
           // rows={200}
            maxRows={Infinity}
            fullWidth
            variant="outlined"
           
            onChange={(event)=> onChange(event.target.value)}
          />
          </>;
  });

  export {SectionNotes}