import axios from "axios";
import {apiPath} from "./config/apiConfig";

const instance = axios.create({
  timeout: 15000,
});

let isOnline = false;

// Set axios default configs
instance.defaults.baseURL = apiPath;

//axios.defaults.baseURL =
instance.defaults.paramsSerializer = function (params) {
  return Object.keys(params)
    .map(function (key) {
      const value =
        key.toLowerCase().indexOf('id') === -1
          ? encodeURIComponent(params[key])
          : params[key]
      return encodeURIComponent(key) + '=' + value
    })
    .join('&')
}

instance.interceptors.request.use(function (config) {
  try {
    const user = JSON.parse(localStorage.getItem('icat-user'));
    const token = user.token;
    if (token) {
      config.headers.AuthToken = token;
    }
  } catch (e) {
    //
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export const onResponseFailed = (cb) => {
  instance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    cb(error);
    return Promise.reject(error);
  });
}



export const on401 = (cb) => {
  onResponseFailed((error) => {
    if (error?.response?.status === 401) {
      cb();
    }
  })
}

export const onOffline = (cb) => {
  onResponseFailed((error) => {
    if (error?.response?.status <= 0 || error.message === "Network Error") {
      isOnline = false;
      cb();
    }
  })
}

export const onOnline = (cb) => {
  instance.interceptors.response.use(function (response) {
    if (!isOnline) {
      isOnline = true;
      cb();
    }
    return response;
  }, function (error) {
    if (!isOnline && error?.response?.status > 0) {
      isOnline = true;
      cb();
    }
    return Promise.reject(error);
  });
}


export default instance;
