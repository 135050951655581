import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'

const useStyles = makeStyles(theme => ({
    root: {                           // - The TextField-root
    marginTop:24,

        // (Note: space or no space after & matters. See SASS "parent selector".)
        '& .MuiOutlinedInput-root': {  // - The Input-root, inside the TextField-root
            '& fieldset': {            // - The <fieldset> inside the Input-root
                borderColor: 'pink',   // - Set the Input border
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary, // - Set the Input border when parent has :hover
            },
            '&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
                borderColor: '#ccc',
            },
        },
    },
    focus: {
     // borderRadius: theme.shape.borderRadius
    
         //outline: 'solid 2px yellow'
    
    },   
  }));

export const TextAreaField = ({ field, value, onChange }) => {
  const [focus, setFocus] = React.useState(null)
  const classes = useStyles();
  const handleOnChange = event => {
    // setValue(value);
    if (onChange) {
      onChange(field, event.target.value)
    }
  }

  const handleOnFocus = () => {
      setFocus(true);
  }

  const handleOnBlur = () => {
    setFocus(false);
}

  return (
    <TextField className={(focus && classes.focus)+" "+classes.root} fullWidth inputProps={{onFocus:handleOnFocus, onBlur:handleOnBlur}}
      label={field.Label}
      multiline
     // rowsMax={8}
     InputLabelProps={{
      shrink: true,
    }}
      value={value||""}
      onChange={handleOnChange}
      variant='outlined'
    />
  )
}
