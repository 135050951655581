import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import CallMissedOutgoingRoundedIcon from '@mui/icons-material/CallMissedOutgoingRounded';
import { useDispatch } from 'react-redux'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { isIOS  } from 'react-device-detect';
const drawerWidth = 240
const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: theme.zIndex.drawer -101+"!important",
      
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto',
  },
}))

const iOS = isIOS ;//process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

export function AuditingDrawer ({handleDrawerClose, handleDrawerOpen,open}) {
  const classes = useStyles()
const dispatch = useDispatch()

  const handleAuditsClicked = () => {
    dispatch({type:"GO_TO_AUDITS"})
    handleDrawerClose()
  }

  const handleSnapshotsClicked = () => {
    dispatch({type:"GO_TO_SNAPSHOTS"})
    handleDrawerClose()
  }

  const handleLogoutClicked = () => {
    dispatch({type:"LOGOUT"})
    handleDrawerClose()
  }

  const handleOutboxClicked = () => {
    dispatch({type:"GO_TO_OUTBOX"})
    handleDrawerClose()
  }

  const handleProfileClicked = () => {
    dispatch({type:"GO_TO_PROFILE"})
    handleDrawerClose()
  }

  const handleSnapshotsAuditsClicked = () => {
    dispatch({type:"GO_TO_SNAPSHOT_AUDITS"})
    handleDrawerClose()
  }


  return (
    <SwipeableDrawer
    disableBackdropTransition={!iOS} disableDiscovery={iOS}
    open = {open||false}
    onOpen={handleDrawerOpen}
    onClose={handleDrawerClose}
      className={classes.drawer}
      variant='temporary'
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
        <ListItem button key={"audits"} onClick={handleAuditsClicked}>
              <ListItemIcon >
               <AssignmentRoundedIcon /> 
              </ListItemIcon>
              <ListItemText primary={"Audits"} />
            </ListItem>
            <ListItem button key={"snapshots"} onClick={handleSnapshotsClicked}>
              <ListItemIcon>
              <AssignmentTurnedInRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={"Snapshots"} />
            </ListItem>
            <ListItem  button key={"snapshots-audits"} onClick={handleSnapshotsAuditsClicked}>
            
              <ListItemText inset primary={"Snapshot Audits"} />
            </ListItem>
            <ListItem button key={"outbox"} onClick={handleOutboxClicked}>
              <ListItemIcon>
              <CallMissedOutgoingRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={"Outbox"} />
            </ListItem>
        </List>
        <Divider />       
        <List>
        <ListItem button key={"profile"} onClick={handleProfileClicked}>
              <ListItemIcon >
               <PersonRoundedIcon /> 
              </ListItemIcon>
              <ListItemText primary={"Profile"} />
            </ListItem>
            <ListItem button key={"logout"} onClick={handleLogoutClicked}>
              <ListItemIcon>
              <LogoutRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItem>
           
        </List>
      </div>
    </SwipeableDrawer>
  )
}
