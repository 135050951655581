import { takeLatest, call, put, select } from 'redux-saga/effects'
import WebStorageLegacyV2 from '../storage/webLegacyV2'

function * checkMigrationNeeded () {
  const oldDatabaseExists = yield call(WebStorageLegacyV2.checkExists)
  yield put({ type: 'app/migrationAvailable', payload: oldDatabaseExists });
  yield put({ type: 'DO_MIGRATION'})

}

function * clearMigrationAvailable () {
  yield put({ type: 'app/migrationAvailable', payload: false })
}

function * doMigration () {
  const {migrationAvailable } = yield select(
    state => state.appState
  )
  if (migrationAvailable) {  

    const migration = new WebStorageLegacyV2();
    yield call([migration, migration.importFromLegacy]);
    yield call([migration, migration.delete]);

  }
 
  yield put({ type: 'app/migrationAvailable', payload: false })
  yield put({ type: 'SYNC'})
  yield put({ type: 'GET_AUDITS'})
  yield put({ type: 'app/setMigrationChecked'})
}

function * migrationSaga () {
  yield takeLatest('CHECK_DATABASE_MIGRATION', checkMigrationNeeded)
  yield takeLatest('DECLINE_MIGRATION', clearMigrationAvailable)
  yield takeLatest('DO_MIGRATION', doMigration)
}

export default migrationSaga
