import { unstable_createMuiStrictModeTheme as createMuiTheme, adaptV4Theme } from '@mui/material/styles';
// https://stackoverflow.com/a/64135466/1850255

const themeOptions = {
    palette: {
      mode: 'dark',
      primary: {
        main: '#fff',
      },
      background: {
        default: '#111111',
        paper: '#212121',
      },
      secondary: {
        main: '#7b95bb',
      },
    },
    typography: {
      fontFamily: 'Sarabun',
      h1: {
        fontFamily: 'Sarabun',
      },
      h2: {
        fontFamily: 'Sarabun',
      },
      h3: {
        fontFamily: 'Sarabun',
      },
      h4: {
        fontFamily: 'Sarabun',
      },
      h6: {
        fontFamily: 'Sarabun',
      },
      h5: {
        fontFamily: 'Sarabun',
      },
      subtitle1: {
        fontFamily: 'Sarabun',
      },
      subtitle2: {
        fontFamily: 'Sarabun',
      },
      button: {
        fontFamily: 'Sarabun',
        fontWeight: 900,
      },
      overline: {
        fontFamily: 'Ubuntu Mono',
      },   
    },
    overrides: {
      MuiAppBar: {       
        colorPrimary: {         
          color:"rgb(255 255 255 / 87%)",
          backgroundColor: "#1d1d1d" // Pink AppBar       
        }     
      }   
    },  
  };

  export const darkTheme = createMuiTheme(adaptV4Theme(themeOptions));
  
