const initialState = {  
  refreshFrequency : 60,
  backgroundSync: true,
  theme: 'light'
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case 'settings/setSetting': {
      return {        
        ...state,
        [action.payload.key]: action.payload.value
      }
    }
    default:
      return {...state}
  }
};