import { IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'


import { useOnline } from '../../../hooks/useUserInfo'



import React from 'react'

import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';



export const SnapshotActionsBar = () => {
  
  const dispatch = useDispatch()
  
  const isOnline = useOnline()
  
  
  
  const isSynchronising = useSelector(store=>store.snapshots.isSynchronising)
 
  const handleSyncToolkits = () => {
    dispatch({type:"SYNC_SNAPSHOTS"})
  }

  return <>
  
  
  <IconButton
    disabled={isSynchronising || !isOnline}
    aria-label='save audit'
    aria-controls='menu-appbar'
    aria-haspopup='false'
    onClick={handleSyncToolkits}
    color='inherit'
    size="large">
        <SaveAltRoundedIcon />
      </IconButton>
   
  </>;
}
