import {urlsV2} from "./config/apiConfig";
import request, {onOffline, onOnline} from "./request";
//import {offline, online} from "./actions/appActions";

const pingInterval = 15 * 1000;
const syncInterval = 60 * 1000;

export const pinger = {
  dispatch:null,
  timeInterval: undefined,
  syncTimer: undefined,
  executor: function () {
    request.get(urlsV2.status).catch(() => {
      // do nothing
    });
  }, 
  start: function (dispatch) {
    this.dispatch = dispatch;    
    this.stop();   
    this.dispatch({type:"SYNC"})
    this.timeInterval = setInterval(this.executor, pingInterval, this)
    this.syncTimer = setInterval(()=> {
      dispatch({type:"SYNC", payload: {type:"background"}})
      dispatch({type:"CHECK_FOR_UPDATE"});
    }, syncInterval, this)
    dispatch({type:"ONLINE"})
    this.executor();
  },
  stop: function () {
    if (typeof this.timeInterval !== 'undefined') {
      clearInterval(this.timeInterval);
    }

    if (typeof this.syncTimer !== 'undefined') {
      clearInterval(this.syncTimer);
    }
  }
};

export default function initialize(dispatch) {
  onOffline(() => dispatch({type:"OFFLINE"}));
  onOnline(() => dispatch({type:"ONLINE"}));
  
}
