import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Switch, FormControlLabel, FormGroup, Typography } from '@mui/material'

const useStyles = makeStyles(theme => ({
  formGroup: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2),
    position:'fixed',
    zIndex:2,
    width:'100%',
    background: theme.palette.background.default,
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)

  },
  formLabel: {
    fontSize:'0.96rem',
  }
}))

export const AuditPageFilterPanel = props => {
  const classes = useStyles()
  const [state, setState] = React.useState({
    showIncomplete: props.showIncomplete
  })

  const handleChange = event => {
      const newState = { ...state, [event.target.name]: event.target.checked };
    setState(newState)
    updateFilter(newState);
  }

  const updateFilter = (newState) => {
    if (!props.onFilterChange) {
      return
    }
    props.onFilterChange(newState)
  }

  return (
    <FormGroup row className={classes.formGroup}>
      <FormControlLabel
        control={
          <Switch
            checked={state.showIncomplete}
            onChange={handleChange}
            name='showIncomplete'
            color='primary'
          />
        }
        label={<Typography className={classes.formLabel}>Show Incomplete</Typography>}      
        labelPlacement='start'
      />
    </FormGroup>
  )
}
