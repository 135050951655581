import { useMemo } from "react";
import styled from "styled-components"




const StatusBar = styled.div`
height:10px;
width:100%;
padding-right:1rem;
display:flex;
margin-top:4px;
padding-left:2px;
margin-bottom:0.5rem;
&>:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
};
&>:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
};
`;

const StatusSegment = styled.div` 
    background-color:#8f8f8f33;
    height:14px;
    display:flex;
`;

const StatusSegmentSuccess = styled(StatusSegment)`
  background-color:#0f9d58;
`;

const StatusSegmentFailed = styled(StatusSegment)`
  background-color:#f77c00;
`;

const StatusSegmentNotApplicable = styled(StatusSegment)`
  background-color:#4285f4;
`;

export const SectionBar = ({summary}) => {

    const pcts = useMemo(()=> {
        const pct = 100 / summary.QuestionCount;
        const success = summary.Success * pct;
        const failed = pct*((summary.QuestionCount - summary.NotAnswered) -summary.Success -        summary.NotApplicable);

        const notApplicable = summary.NotApplicable * pct;
        let notAnswered = 100 - notApplicable - failed - success;
        if (notAnswered < 0.5) notAnswered = 0;

        return {
            success,failed,notApplicable,notAnswered
        }
    },[summary])

    return (
<StatusBar>
                {pcts.success!==0 && <StatusSegmentSuccess style={{ width: pcts.success + "%"}}/>}
                {pcts.failed!==0&& <StatusSegmentFailed style={{ width: pcts.failed + "%" }}/>}
                {pcts.notApplicable!==0&& <StatusSegmentNotApplicable style={{ width: pcts.notApplicable + "%" }}/>}
                {pcts.notAnswered!==0&& <StatusSegment style={{ width: pcts.notAnswered + "%"}}/>}
          
</StatusBar>)
}