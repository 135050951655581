import { Badge, Card, CardHeader, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useMemo } from 'react'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { MoreVertRounded } from '@mui/icons-material'
import { ThemeProvider, StyledEngineProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { QuestionItem } from './QuestionItem'
import { useDispatch, useSelector } from 'react-redux'
import { isQuestionAnswered } from '../../../util/AuditUtils'
import { SectionBar } from './SectionBar'
import { lightTheme } from '../../../theme/light'
import { darkTheme } from '../../../theme/dark'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import { NotesDialog } from '../AuditListPage/NotesDialog'

const useStyles = makeStyles(theme => ({
  cardRoot: {
    margin: theme.spacing(1)
  },
  cardHeaderRoot: {
    padding: theme.spacing(1.5)
  },
  dialogAppBar: {
    position: 'relative'
  },
  section: {},
  sectionTitle: {
    paddingRight: theme.spacing(1),
    fontSize: '0.98rem',
    fontFamily: theme.typography.fontFamily
  },
  status: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  sectionGrid: {
    alignItems: 'center',
    justifyContent: 'start',
    display: 'flex'
  },
  header: {
    position: 'relative',
    justifyContent: 'center',
    paddingLeft: 5
  },
  textField: {
    minHeight: 'calc(100vh - 100px)',
    alignItems:'start'
  },
  highlightedMenu: {
    color: theme.palette.primary.main
  }
}))

export const Section = React.memo(({
  section,
  toolkitInstance,
  auditId,
  answers,
  onQuestionClick,
  filter,
  audit
}) => {
  //const [questions, setQuestions] = React.useState(null)
  
  const [notesOpen, setNotesOpen] = React.useState()
  const settings = useSelector(store => store.settings)
    
  const [expanded, setExpanded] = React.useState(null)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null)
  const isSnapshot = audit && audit.AuditType === "Snapshot";
  const supportQuickActions = toolkitInstance && (toolkitInstance.SupportQuickActions || (toolkitInstance.SupportQuickActions==null && !isSnapshot));

  const getTheme = () => {
    return settings.theme === 'dark' ? darkTheme : lightTheme
  }

  let theme = createTheme()
  theme = responsiveFontSizes(getTheme())

  const questions = useMemo(() => {
    if (!toolkitInstance || !section) {
      return null
    }
    let questionsInSection = toolkitInstance.Questions.filter(
      q => q.SectionId === section.Id
    )
  
    return questionsInSection
  }, [toolkitInstance, section])

  const sectionNote = useMemo(() => {
    if (!audit||!section) {
      return ""
    }

    const sectionNote = audit.SectionNotes && audit.SectionNotes.find(s=>s.Id===section.Id);
    return (sectionNote && sectionNote.Notes) || "";

  }, [audit, section])

  const answersForSection = useMemo(() => {
    if (!answers || !questions) {
      return null
    }

    return answers.filter(p => questions.map(q => q.Id).includes(p.QuestionId))
  }, [answers, questions])

  const filteredQuestions = useMemo(() => {
    return getFilteredQuestions(questions, filter, answersForSection)
  }, [questions, filter, answersForSection])

  const questionSummary = useMemo(() => {
    if (!answers || !questions) {
      return null
    }

    const questionSummary = answersForSection.reduce(
      (prev, answer) => {
        if (isQuestionAnswered(answer)) {
          prev.Success += answer.Success === true ? 1 : 0
          prev.Fail += answer.Success === false ? 1 : 0
          prev.NotApplicable += answer.NotAnswsered ? 1 : 0
        }

        return prev
      },
      { Success: 0, Fail: 0, NotApplicable: 0 }
    )

    questionSummary.QuestionCount = questions.length
    questionSummary.NotAnswered =
      questions.length -
      questionSummary.Success -
      questionSummary.Fail -
      questionSummary.NotApplicable

    return questionSummary
  }, [answersForSection, questions, answers])

  const handlerExpandedClick = useCallback(() => {
        
    setExpanded(!expanded)
    //dispatch({type:"SET_SECTION_STATE",payload:{id:section.Id, state:{expanded:!expanded}}})
      
  }, [setExpanded, expanded])

  const handleMenuClick = useCallback(
    event => {
      setMenuAnchorEl(event.currentTarget)
    },
    [setMenuAnchorEl]
  )

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null)
  }, [setMenuAnchorEl])

  const markAllAsCompliant = useCallback(() => {
    handleMenuClose()
    setTimeout(() => {
      const unansweredQuestions = getFilteredQuestions(
        questions,
        { showIncomplete: true },
        answers
      )
      const update = unansweredQuestions.map(p => {
        return { fieldType:'RadioField', fieldName: 'Compliancy', value: 0, questionId: p.Id }
      })

      dispatch({ type: 'UPDATE_AUDIT_ANSWERS', payload: update })
    }, 0)
  }, [dispatch, handleMenuClose, questions, answers])

  const markAllAsNotApplicable = useCallback(() => {
    handleMenuClose()
    setTimeout(() => {
      const unansweredQuestions = getFilteredQuestions(
        questions,
        { showIncomplete: true },
        answers
      )
      const update = unansweredQuestions.map(p => {
        return {fieldType:'RadioField', fieldName: 'Compliancy', value: 2, questionId: p.Id }
      })

      dispatch({ type: 'UPDATE_AUDIT_ANSWERS', payload: update })
    }, 0)
  }, [dispatch, handleMenuClose, questions, answers])

  const handleCloseNotes = useCallback(() => {
    setNotesOpen(false)
  }, [setNotesOpen])

  const handleOpenNotes = useCallback(() => {
    handleMenuClose()
    setNotesOpen(true)
  }, [setNotesOpen, handleMenuClose])

  const renderQuestions = () => {
    if (!filteredQuestions || !answersForSection) {
      return null
    }

    const renderedQuestions = filteredQuestions.map(q => (
      <QuestionItem
        sectionAnswers={answersForSection}
        key={q.Id}
        question={q}
        onClick={onQuestionClick}
      />
    ))

    return (
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        {renderedQuestions}
      </Collapse>
    )
  }

  const handleSaveNotes = useCallback((sectionId, notes) => {   
    setNotesOpen(false)
    dispatch({type:"UPDATE_SECTION_NOTES", payload: {sectionId: sectionId, notes: notes}})
  }, [dispatch])

  const renderMenu = () => {

    if (!supportQuickActions) {
return (
  <Badge invisible={!sectionNote} color="primary" variant="dot"><IconButton aria-label='sections-more' onClick={handleOpenNotes} size="large">
  <CreateRoundedIcon />
  </IconButton></Badge>
);
    }

    return <>      
        <Badge invisible={!sectionNote} color="primary" variant="dot"><IconButton aria-label='sections-more' onClick={handleMenuClick} size="large">
          <MoreVertRounded />
        </IconButton></Badge>
    <Menu
      id='simple-menu'
      anchorEl={menuAnchorEl}
     
      open={Boolean(menuAnchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={markAllAsCompliant}>Mark all as compliant</MenuItem>
      <MenuItem onClick={markAllAsNotApplicable}>Mark all as N/A</MenuItem>
      <MenuItem onClick={handleOpenNotes} className={sectionNote?classes.highlightedMenu:null}>Notes</MenuItem>
    </Menu>
    </>;
  }

  const cardHeader = () => (
    <Grid item xs={12} sm container>
      <Grid onClick={handlerExpandedClick}
        className={classes.header}
        item
        xs
        container
        direction='column'
        spacing={2}
      >
       <Grid container direction='column'>
          <Grid item className={classes.sectionGrid}>
            <Typography variant='subtitle1' className={classes.sectionTitle}>
              {section.Name}
            </Typography>
       
          </Grid>
          <Grid item>
            <SectionBar summary={questionSummary} />
          </Grid>
        </Grid>
      </Grid>
      

      <Grid item classes={{ root: classes.status }}>
      <IconButton
        color='primary'
        aria-label='expand'
        onClick={handlerExpandedClick}
        size="large">
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
         {renderMenu()}
        {' '}
      </Grid>
    </Grid>
  )

  return <>
   <NotesDialog notesOpen={notesOpen} onClosed={handleCloseNotes} onNotesChanged={handleSaveNotes} sectionId={section.Id} sectionNotes={sectionNote}/>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Card className={classes.cardRoot}>
          <CardHeader
            className={classes.cardHeaderRoot}
            title={cardHeader()}
            titleTypographyProps={{ variant: 'h5', className: classes.section }}
          />
          {renderQuestions()}
        </Card>
      </ThemeProvider>
    </StyledEngineProvider>
  </>;
})

function getFilteredQuestions (questions, filter, answers) {
  let filtered = questions
  if (filter) {
    if (filter.showIncomplete) {

      filtered = filtered.filter(q=> {
        return answers.findIndex(a =>  (a.NotAnswsered || a.Success!=null) && a.QuestionId === q.Id) === -1
      })     
    }
  }

  return filtered
}
