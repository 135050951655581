import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react'
import { QuestionField } from './Fields/QuestionField'
import { useParams } from 'react-router-dom'
import ShowMoreText from 'react-show-more-text'

import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  questionText: {
    fontSize: '1.4rem',
    fontWeight: 'bold'
  },
  questionBody: {
    fontSize: '0.9rem'
  },
  section: {
    fontSize: '0.8rem',
    paddingRight: theme.spacing(0.75),
    color: theme.palette.primary.light,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    fontWeight: 'bold'
  },
  rationale: {
    fontSize: '1.1rem',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  box: {
    //paddingBottom: theme.spacing(2),
    paddingBottom: 62
  }
}))

const useClientRect = () => {
  const [rect, setRect] = React.useState(null)
  const ref = React.useCallback(node => {
    if (node !== null) {
      setRect(node.getBoundingClientRect())
    }
  }, [])
  return [rect, ref]
}

export const AuditQuestionPage = ({
  validationErrors,
  toolkitInstance,
  answers,
  onChange,
  navigationBar
}) => {
  const { question: questionId } = useParams()
  
  const classes = useStyles()
  const [rect, ref] = useClientRect()
  const dispatch = useDispatch();

  const question = useMemo(() => {
    if (!toolkitInstance) {
      return null
    }

    return toolkitInstance.Questions.find(p => p.Id === questionId)
  }, [toolkitInstance, questionId])

  const questionAnswers = useMemo(() => {
    if (!answers) {
      return null
    }

    return answers.find(p => p.QuestionId === questionId)
  }, [answers, questionId])

  const section = useMemo(() => {
    if (!toolkitInstance || !question) {
      return null
    }

    return toolkitInstance.Sections.find(s => s.Id === question.SectionId)
  }, [question, toolkitInstance])

  const handleOnChange = (field, value) => {
   
    const fieldUpdates = [];

    const fieldUpdate = {
      fieldName: field.Name,
      value: value,
      questionId: questionId,
      fieldType: field.Type
    }

    const expComments =  {
      fieldName: "ExpertComments",
      value: [],
      questionId: questionId,
      fieldType: "CheckboxField"
    };

    if (field.Name==="Compliancy") {
      if (value!==1) {
        dispatch({type:"UPDATE_AUDIT_ANSWERS", payload: [expComments]})   
      }
    }

    fieldUpdates.push(fieldUpdates)

    dispatch({type:"UPDATE_AUDIT_ANSWERS", payload: [fieldUpdate]})    
   
     
  }

  if (!question) {
    return null
  }

  const renderField = field => {
    return (
      <QuestionField
        key={'question-field-' + field.Name}
        field={field}
        question={question}
        answers={questionAnswers}
        onChange={handleOnChange}
      />
    )
  }

  const renderFields = () => {
    return question.Fields.map(field => renderField(field))
  }

  return (
    <>
      <Box className={classes.box} m={2}>
        <Typography variant='subtitle1' className={classes.section}>
          {section.Name}
        </Typography>
        <Typography variant='h5' className={classes.questionText}>
          {question.QuestionText}
        </Typography>
        <Typography variant='h6' className={classes.rationale}>
          {question.Rationale && question.Rationale.Description}
        </Typography>
        <Typography ref={ref}>
          <ShowMoreText
            /* Default options */
            lines={8}
            more='Show more'
            less='Show less'
            className={classes.questionBody}
            anchorClass='my-anchor-css-class'
            expanded={false}
            width={rect && rect.width - 10}
            keepNewLines={true}
          >
            {question.RationaleText}
          </ShowMoreText>
        </Typography>
        {renderFields()}
      </Box>
      {navigationBar}
    </>
  )
}
