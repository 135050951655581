const Config = {
//"apiRoot": "http://localhost/icat",
    // "apiRoot": "https://ips-icat-api.azurewebsites.net",
    //"apiRoot" : "https://api.icaudit.com.au/ukapi",
     //apiRoot: "http://localhost/icat",
   apiRoot: "https://api.icaudit.co.uk",
    // apiRoot: "https://api.icaudit.co.uk/staging",
    //apiRoot: "https://api.icaudit.com.au/demo",
    //apiRoot: "https://ipa-api.icaudit.com.au",
    gaCode: "UA-61471093-2", // aus= UA-61471093-3
    brand: "icat",
    splashHeight: 112,
    international: false,
    productName: "Audit Management Console",
    companyName: "Infection Prevention",
    licensor: "IPC Management Limited",
    offproductionUrl: "https://www.icaudit.co.uk"
}

export default Config;