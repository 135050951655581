import React from 'react';
import {useDisclaimer} from "../../../hooks/useUserInfo";
import { LoginForm, Disclaimer } from '../../templates/LoginForm';
import {Page} from '../Page';

export const LoginPage = () => {
  const disclaimer = useDisclaimer();
  
  return (
    <Page>
      {disclaimer ? (<Disclaimer />) : <LoginForm/>}
    </Page>
  );
}