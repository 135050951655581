import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { List, Toolbar } from '@mui/material';
import { SnapshotToolkitItem } from './SnapshotToolkitItem';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    //width: 500,
  },
  tabPanel: {
    paddingTop:theme.spacing(1)
  },
  list: {
    padding: theme.spacing(4)
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
const classes = useStyles()
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.tabPanel} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function SnapshotToolkits() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const snapshotsToolkits = useSelector(store => store.snapshots.toolkits)
  const facilities = useSelector(store => store.snapshots.facilities)
 
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  


  const renderToolkits = () => {
    if (!snapshotsToolkits) {
      return null;
    }
  return (
   
      <List>
      {snapshotsToolkits.map(tki=><SnapshotToolkitItem key={tki.Id} toolkit={tki}/>)}
      </List>
   
  )
}

const renderFacilities = () => {
  if (!facilities) {
    return null;
  }
return (

    <List>
    {facilities.map(p=> <div key={p.Id}>{p.Name}</div>)}
    </List>

)
}

  return (
    <>
    <Toolbar/>
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Snapshots" {...a11yProps(0)} />
          {false && <Tab disabled label="Facilities" {...a11yProps(1)} />}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
       
       
        
        {renderToolkits()}
     
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        {renderFacilities()}
        </TabPanel>
      
      </SwipeableViews>
    </div>
    </>
  );
}