import { all, call, put, takeLatest } from 'redux-saga/effects'
import { asyncLocalStorage } from '../util/asyncLocalStorage'

export function setSetting (data) {
  return { type: 'settings/setSetting', payload: data }
}

function * loadSetting (key, defaultValue) {
  const value = yield call(asyncLocalStorage.getItem, key)
  yield put(setSetting({ key, value: (value || defaultValue)}))
}

function * loadSettings () {
  yield all([
    call(loadSetting, 'theme'),
    call(loadSetting, 'backgroundSync', true),
    call(loadSetting, 'refreshFrequency', 60)
  ])
}

function * writeSetting (action) {
  const { key, value } = action.payload
  yield call(asyncLocalStorage.setItem, key, value)
  yield put(setSetting({ key, value }))
}

function * settingsSaga () {
  yield takeLatest('LOAD_SETTINGS', loadSettings)
  yield takeLatest('WRITE_SETTING', writeSetting)
}

export default settingsSaga
