import { Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Button } from "@mui/material";
import { Alert } from '@mui/material';

export const ValidationErrorModal = ({open, messages, onClose}) => {
    
    const handleOnClose = () => {
        if (onClose) {
            onClose();
        }
    }
    return (  
    <Dialog
        open={open}       
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Question Incomplete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {messages && messages.map(p=> <Alert severity="error">{p}</Alert>)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose} color="primary" autoFocus>
            OK
          </Button>        
        </DialogActions>
      </Dialog>)
}