import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { useState } from 'react'

import { SelectLocationDialog } from './SelectLocationDialog'
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined'

export const SnapshotToolkitItem = ({ toolkit }) => {
  const theme = useTheme()

  const [openLocationDialog, setOpenLocationDialog] = useState(false)
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'))

  const handleCreateSnapshot = () => {
    setOpenLocationDialog(true)
  }

  const handeCloseDialog = () => {
    setOpenLocationDialog(false)
  }

  return (
    <>
      {openLocationDialog && (
        <SelectLocationDialog
          toolkitInstance={toolkit}
          onClose={handeCloseDialog}
        />
      )}
      <ListItem
        disableGutters
        onClick={handleCreateSnapshot}
        dense={smallDevice}
        button
        key={toolkit.Id}
      >
        {' '}
        <ListItemAvatar>
          <Avatar>
            <OfflineBoltOutlinedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={toolkit.InstanceName}
          secondary='Snapshot Toolkit'
          primaryTypographyProps={{ variant: 'h6' }}
        />
      </ListItem>
    </>
  )
}
