import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
   {
    button {
      border-radius: 3px !important;
      border-top-left-radius: 3px !important;
      border-bottom-left-radius: 3px !important;
      border-left: solid 1px rgba(0, 0, 0, 0.12) !important;
     
    }
  }
`
const StyledToggleButton = withStyles(theme => ({
  root: {
    // borderRadius: theme.shape.borderRadius
    '&:focus': {
    //  outline: 'solid 2px yellow'
    }
  },

  selected: {
    backgroundColor: theme.palette.primary.main + '',
    color: 'white!important'
  }
}))(ToggleButton)

export const RadioField = ({ field, disabled, value, onChange }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

 const radioStylesSelected = { 
    0 : {
      backgroundColor:'#0f9d58'
    },
    1: {      
      backgroundColor: '#f77c00',
    },
    2: {
      backgroundColor: '#4285f4'
    }

 }


  return (
    <Grid style={{marginTop:'12px'}} container align='center' justifyContent='left' alignItems='center'>
      <StyledToggleButtonGroup
        onChange={(event, value) => { if(value===null) return; onChange(field, value); return true;}}
        size={isSmall ? 'small' : 'large'}
        value={value}       
        exclusive
        aria-label='Compliance value'
      >
        {field.Items.map(item =>   <StyledToggleButton style={value?.toString()===item?.Value.toString()?radioStylesSelected[item.Value]:null}
      key={'xxfield-' + field.Name + '-' + item.Value}
      value={item.Value}
     // autoFocus={item.Value===value}
      aria-label='left aligned'
      disabled={disabled}
    >
      {item.Text}
    </StyledToggleButton>)}
      </StyledToggleButtonGroup>
    </Grid>
  );
}
