/* eslint-disable no-eval */
/* eslint-disable no-useless-escape */

import _ from 'lodash'

export function buildScope(answers) {
    let scope = {};
    answers.forEach(item => {
        if (item.Value === undefined || item.Value === null) {
            return true;
        }

        if (typeof item.Value === "string") {
            scope[item.FieldName] = item.Value.replace(/\s/g, "");
        } else {
            scope[item.FieldName] = item.Value;
        }
    });

    scope.any = (argStr, scope) => {
        let ok = false;

        const getAny = (value) => {
            if (value == null) {
                return false;
            }

            else if (Array.isArray(value)) {
                return value.length > 0;
            } else {
                return !!value;
            }
        };

        argStr.split(",").forEach(arg => {
            ok = scope.hasOwnProperty(arg) ? !!getAny(scope[arg]) : false;
            if (ok) {
                return false;
            }
        });

        return ok;
    };

    return scope;
}

export function interpolate(scope, expression, defaultValue) {

    const regEx = /\{\{[\w|(|)|\d]*\}\}/g;
    const fnRegEx = /(\w*)\(([a-zA-Z0-9,]*)/gi;

    expression = expression.replace(/\'/g,"");

    try {
        let result = expression.replace(regEx,
        (match) => {

            const marr = fnRegEx.exec(match);
            if (marr != null) {
                const fn = marr[1];
                if (scope[fn]) {
                    return scope[fn](marr[2],scope);
                }
            }

            const propName = match.replace(/[\{|\}]/g, "");
            if (scope.hasOwnProperty(propName)) {
                const returnValue = scope[propName];

                if (_.isString(returnValue)) {
                    return "'"+returnValue.replace(/\'/g," ")+"'";
                }
                
                return returnValue;

            }

            if (defaultValue!=null) {
                return ` ${defaultValue} && null `;
            }
            else {
                throw new Error('property not present') ;
            }
        });

        result=result.replace("'null'","''")
        
        return eval(result);
    } catch (e) {
        return null;
    }
}