import { Fade, IconButton, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useOnline, useUser } from '../../../hooks/useUserInfo'
import CachedIcon from '@mui/icons-material/Cached'
import CircularProgress from '@mui/material/CircularProgress'
import AccountCircle from '@mui/icons-material/AccountCircle'
import React from 'react'
import { useSyncStatus } from '../../../hooks/useAudits'

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(0),
    position: 'relative'
  },
  syncProgress: {
    color: theme.palette.info.light,
    position: 'absolute',
    top: 4,
    left: 5,
    zIndex: 1
  }
}))

export const ApplicationActions = ({ disabled }) => {
  const userInfo = useUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const isOnline = useOnline()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const classes = useStyles()
  const isSynchronising = useSyncStatus()
 
  const handleProfileClick = () => {
    history.push('/profile')
  }

  const handleSynchronise = () => {
    dispatch({type:"SYNC"})
  }

  const handleLogout = () => {
    handleClose()
    dispatch({type:"LOGOUT"})
  }

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return <>
    <div className={classes.wrapper}>
      <IconButton
        disabled={isSynchronising || !isOnline}
        aria-label='synchronising indicator'
        aria-controls='menu-appbar'
        aria-haspopup='false'
        onClick={handleSynchronise}
        color='inherit'
        size="large">
        <CachedIcon />
      </IconButton>

      <Fade
        in={isSynchronising && isOnline}
        style={{
          transitionDelay: isSynchronising ? '800ms' : '0ms'
        }}
        unmountOnExit
      >
        <CircularProgress
          size={38}
          thickness={5}
          className={classes.syncProgress}
        />
      </Fade>
    </div>
    <IconButton
      disabled={disabled}
      aria-label='account of current user'
      aria-controls='menu-appbar'
      aria-haspopup='true'
      onClick={handleMenu}
      color='inherit'
      size="large">
      <AccountCircle />
    </IconButton>
    <Menu
      id='menu-appbar'
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={open || false}
      onClose={handleClose}
    >
      <MenuItem onClick={handleProfileClick}>{userInfo.FullName}</MenuItem>
      <MenuItem onClick={handleLogout}>Log Out</MenuItem>
    </Menu>
  </>;
}
