import { Switch, Route } from 'react-router-dom'
import React, { useEffect } from 'react'
import { Redirector } from './components/pages/redirector'
import { LoginPage } from './components/pages/Login'
import { AuditListPage } from './components/pages/AuditListPage'
import { ForgotPasswordPage } from './components/pages/ForgotPassword'
import { ProfilePage } from './components/pages/ProfilePage'
import { useDispatch } from 'react-redux'

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { lightTheme } from './theme/light'
import { darkTheme } from './theme/dark'
import { useSelector } from 'react-redux'
import { AuditPage } from './components/pages/AuditPage'
import { useUser } from './hooks/useUserInfo'
import { pinger } from './sync'
//import {syncAudits} from "./actions/auditActions";
import CssBaseline from '@mui/material/CssBaseline'
import { SnapshotPage } from './components/pages/SnapShots'
import { Workbox } from 'workbox-window'
import ApplicationUpdateAvailableDialog from './components/shared/ApplicationUpdateAvailableDialog'
import MigrationAvailableDialog from './components/shared/MigrationAvailableDialog'

function App () {
  const settings = useSelector(store => store.settings)
  const dispatch = useDispatch()
  const migrationAvailable = useSelector( state => state.appState.migrationAvailable )
  const updateAvailable = useSelector(state => state.appState.updateAvailable)
  
  useEffect(() => {
    dispatch({ type: 'LOAD_SETTINGS' })
    dispatch({type:'CHECK_DATABASE_MIGRATION'})
  }, [dispatch])

  const getTheme = () => {
    return settings.theme === 'dark' ? darkTheme : lightTheme
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getTheme()}>
        {updateAvailable && <ApplicationUpdateAvailableDialog />}
        {!updateAvailable && migrationAvailable && <MigrationAvailableDialog />}
        <CssBaseline />
        <Redirector />
        <Switch>
          <Route exact path='/login'>
            <LoginPage />
          </Route>
          <Route exact path='/login/forgot'>
            <ForgotPasswordPage />
          </Route>
          <Route path='*' render={() => <Authenticated />} />
        </Switch>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const Authenticated = React.memo(props => {
  const user = useUser()
  const dispatch = useDispatch()
  useEffect(() => {
    if (user.isAuthenticated) {      
      pinger.start(dispatch)     
    } else {
      pinger.stop()
    }

    if ('serviceWorker' in navigator) {
      const wb = new Workbox('/sw.js') 

      wb.addEventListener('installed', event => {
        dispatch({ type: 'app/installed', payload: true })
       // if (event.isUpdate) {
          
        
          

         // dispatch({ type: 'app/setUpdateAvailable', payload: true })       
       // }
      })
      wb.register()
      window.wb = wb
    }
  }, [user, dispatch])

  useEffect(()=>{
      return () => {
        pinger.stop();
      }
  })


  return (
    <Switch>
      <Route
        exact
        path='/audits'
        render={() => <AuditListPage type='audit' />}
      />
      <Route exact path='/snapshots'>
        <SnapshotPage />
      </Route>
      <Route
        exact
        path='/snapshots/audits'
        render={() => <AuditListPage type='snapshots' />}
      />
      <Route
        exact
        path='/outbox'
        render={() => <AuditListPage type='outbox' />}
      />

      <Route path='/audits/:id' render={() => <AuditPage />} />
      <Route exact path='/profile'>
        <ProfilePage />
      </Route>
    </Switch>
  )
})

export default App
