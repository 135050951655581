import {  
  put,
  takeEvery,   
} from 'redux-saga/effects'
import { push } from 'connected-react-router';

function* gotoAudits() {
  yield put(push("/audits"))
}

function* gotoSnapshots() {
  yield put(push("/snapshots"))
}

function* gotoAudit(action) {
  yield put(push(`/audits/${action.payload.AuditInternalId}`))
}

function* gotoSnapshotAudits() {
  yield put(push("/snapshots/audits"))
}

function* gotoProfile() {
  yield put(push("/profile"))
}

function* gotoOutbox() {
  yield put(push("/outbox"))
}


function * navigationSaga () {
    yield takeEvery('GO_TO_AUDITS', gotoAudits)    
    yield takeEvery('GO_TO_SNAPSHOTS', gotoSnapshots)    
    yield takeEvery('GO_TO_SNAPSHOT_AUDITS', gotoSnapshotAudits)    
    yield takeEvery('GO_TO_AUDIT', gotoAudit)    
    yield takeEvery('GO_TO_PROFILE', gotoProfile)    
    yield takeEvery('GO_TO_OUTBOX', gotoOutbox)        
  }
  
export default navigationSaga
  