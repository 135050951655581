import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore, {history} from './configureStore'
import { Provider } from 'react-redux'
//import {
 // BrowserRouter as Router 
//} from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router'
import {on401} from "./request";
//import {logout} from "./actions/appActions";
import initialize from "./sync";
import ScrollToTop from './util/ScrollToTop';

const store = configureStore()

// this needs to be false for MUI themes
const strict = false;

const WrapStrictMode = ({children}) => {
  if (strict) {
    return <React.StrictMode>
      {children}
    </React.StrictMode>
  }

  return <>{children}</>;
}

ReactDOM.render(
  <WrapStrictMode>    
     <Provider store={store}>
     <ConnectedRouter history={history}> 
       <ScrollToTop/>
       <App />
       </ConnectedRouter>
    </Provider>
  </WrapStrictMode>,
  document.getElementById('root')
);

ReactDOM.render(
  <WrapStrictMode>    
     <Provider store={store}>
     <ConnectedRouter history={history}> 
       <ScrollToTop/>
       <App />
       </ConnectedRouter>
    </Provider>
  </WrapStrictMode>,
  document.getElementById('root')
);

on401(() => store.dispatch({type:"LOGOUT"}));
initialize(store.dispatch);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
