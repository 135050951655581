import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModifiedExternallyDialog({open, auditInternalId, onCloneAudit, onDiscardAudit, onCancel}) {

  const handleCopyIntent = () => {
    handleCancelIntent();
    if (onCloneAudit) {
        onCloneAudit(auditInternalId);
    }
  };

  const handleDiscardIntent = () => {
    handleCancelIntent();
    if (onDiscardAudit) {
        onDiscardAudit(auditInternalId);
    }
  }

  const handleCancelIntent = () => {
    if (onCancel) {
        onCancel(auditInternalId);
    }
  }

  return <>    
    <Dialog
      open={open||false}
      disableEscapeKeyDown
      TransitionComponent={Transition}
      keepMounted
      // onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle id="alert-dialog-slide-title">{"This audit has been modified"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Another auditor has made changes to this audit whilst you have had it checked out. What would you like to do?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
      <Button variant="outlined" onClick={handleCancelIntent} >
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleCopyIntent} color="primary">
          Copy
        </Button>
        <Button variant="outlined" onClick={handleDiscardIntent} color="secondary">
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  </>;
}
