import React, { useCallback, useEffect, useMemo } from 'react'
import { PrimaryAppBar } from '../../shared/AppBar'
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar'
import {  Paper } from '@mui/material'
import { useParams } from 'react-router'
/*import {
  getAudit,
  getAnswersForAudit,
  getSectionsForToolkitInstance,
  cloneAndDiscardAudit,
  discardAudit,
  markAuditAsRead
} from '../../../actions/auditActions'*/


//import ModifiedExternallyDialog from './ModifiedExternallyDialog'
import { useDispatch, useSelector } from 'react-redux'
import { AuditQuestionPage } from '../AuditQuestionPage.js'
import { Switch, Route, useHistory } from 'react-router-dom'
import { ValidationErrorModal } from './ValidationErrorModal'
import { QuestionAppBar } from '../AuditQuestionPage.js/QuestionAppBar'
import { matchPath } from 'react-router'
import { AuditActionsBar } from '../../shared/AppBar/AuditActionsBar'
import { AuditStatus } from '../../../util/constants'
import { AuditPageBody } from './AuditPageBody'

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100vh'
  }
}))

 const AuditPage = React.memo(props => {
  
  const classes = useStyles()
  const { id } = useParams()
 
  const [showValidationMessages, setShowValidationMessages] = React.useState(null)
  const {
    answers,
    sections,
    toolkitInstance,
    audit,
    validationMessages
  } = useSelector(store => store.auditEntry)

  const history = useHistory();
  const [filter, setFilter] = React.useState({ showIncomplete: false })
  const dispatch = useDispatch()
  
  const hasValidationMessages = validationMessages && validationMessages.length

  const toolkitSections = useMemo(() => {
    if (!toolkitInstance) {
      return null
    }

    let filteredSections = toolkitInstance.Sections

    if (filter && filter.showIncomplete) {
      const sectionAnswers = sections.map(p => {
        return {
          SectionId: p.SectionId,
          AnswerCount: p.QuestionIds
            ? answers.filter(a => (a.NotAnswsered || a.Success) && p.QuestionIds.includes(a.QuestionId)).length
            : 0,
          QuestionCount: p.QuestionIds ? p.QuestionIds.length : 0
        }
      })

      const sectionsWitouthAnswers = sectionAnswers.filter(
        s => s.AnswerCount !== s.QuestionCount
      )
      if (sectionsWitouthAnswers && sectionsWitouthAnswers.length) {
        filteredSections = filteredSections.filter(p =>
          sectionsWitouthAnswers.map(a => a.SectionId).includes(p.Id)
        )
      }
    }

    return filteredSections
  }, [filter, toolkitInstance, sections, answers])

  const filteredSections = useMemo(() => {
    if (!toolkitSections || !sections) {
      return null
    }

    return sections.filter(x => toolkitSections.some(y => x.SectionId === y.Id))
  }, [toolkitSections, sections])

  useEffect(() => {
    dispatch([
      { type: 'GET_AUDIT', payload: id },
      { type: 'MARK_AUDIT_AS_READ', payload: id }
    ])

    return () => dispatch({ type: 'CLEAR_CURRENT_AUDIT' })
  }, [id, dispatch])

  const handleFilterChanged = useCallback((filterModel)=> {
    setTimeout(() => {
      setFilter(filterModel)
    }, 0)
  },[]);


  /* const handleDiscardAudit = async () => {
    setAudit(null)
    setAnswers(null)
    const { audit: remoteAudit, answers: remoteAnswers } = await dispatch(
      discardAudit(audit.Id)
    )
    setAudit(remoteAudit)
    setAnswers(remoteAnswers)
  }

  const handleCloneAudit = async () => {
    setAudit(null)
    setAnswers(null)
    const { audit: newAudit, answers: newAnswers } = await dispatch(
      cloneAndDiscardAudit(audit.InternalId)
    )
    setAudit(newAudit)
    setAnswers(newAnswers)
  }

  const handleCancelExternalUpdate = () => {
    history.goBack()
  }
*/




  const handleNavigationWithMessages = () => {
    setShowValidationMessages(true)
  }

  const handleValidationMessagesAckowledged = () => {
    setShowValidationMessages(false)
  }

   

  

  
  

  const isNestedRoute = () => {
    const match = matchPath(history.location.pathname, {
      path: '/audits/:id/:question',
      exact: true,
      strict: false
    })
    if (!match) {
      return false
    }

    return match.isExact
  }

  const auditPageRoute = () => {
    return (
      <Switch>
        <Route exact path='/audits/:id' render={()=><AuditPageBody onFilterChange={handleFilterChanged} filter={filter} audit={audit} toolkitInstance={toolkitInstance} answers={answers} sections={toolkitSections}/>}/>      
        
        <Route
          exact
          path='/audits/:id/:question'
          render={props => (
            <AuditQuestionPage
              {...props}
              //onChange={(value)=>handleUpdateQuestionValue([value])}
              toolkitInstance={toolkitInstance}
              answers={answers}
              validationErrors={validationMessages}
              navigationBar={
                <QuestionAppBar
                  auditId={id}
                  sections={filteredSections}
                  disabled={hasValidationMessages}
                />
              }
            />
          )}
        />
      </Switch>
    )
  }

  const getBackRoute = () => {
    if (audit.Status === AuditStatus.COMPLETE) {
      return '/outbox'
    }

    if (audit.AuditType === 'Snapshot') {
      return '/snapshots/audits'
    }

    return '/audits'
  }

  return (
    <>
      {showValidationMessages &&
        validationMessages &&
        validationMessages.length && (
          <ValidationErrorModal
            open={showValidationMessages}
            messages={validationMessages}
            onClose={handleValidationMessagesAckowledged}
          />
        )}
      <PrimaryAppBar
        title={toolkitInstance && audit && audit.LocationName}
        subtitle={toolkitInstance && toolkitInstance.InstanceName}
        //goBack={validationErrors && validationErrors.length ? false : true}
        onBack={
          isNestedRoute() === false
            ? () => history.push(getBackRoute())
            : validationMessages && validationMessages.length
            ? handleNavigationWithMessages
            : () => history.push(`/audits/${id}`)
        }
        disabled={hasValidationMessages}
        actions={
          isNestedRoute() === false ? <AuditActionsBar audit={audit} /> : null
        }
      />
      <Paper className={classes.paper} elevation={0}>
        <Toolbar />
        {auditPageRoute()}
      </Paper>
    </>
  )
});
AuditPage.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: 'AuditPage'
}
export {AuditPage};