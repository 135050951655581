import { Checkbox, FormControlLabel } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

const StyledCheckBox = withStyles(theme => ({
    root: {
     // borderRadius: theme.shape.borderRadius
     '& input:focus + .MuiSvgIcon-root' : {
        // outline: 'solid 2px yellow'
     }
    },   
  }))(Checkbox)

export const CheckBoxItem = ({onItemChecked, disabled, item, checked, label}) => {

    const handleOnChange = (event) => {
        if (!onItemChecked) {
            return true;
        }

        onItemChecked(item, event.target.checked);
    }

    return <FormControlLabel disabled={disabled} style={{ display: "table" }} control={ <div style={{ display: "table-cell" }}><StyledCheckBox color="primary" disabled={disabled} onChange={handleOnChange} checked={checked}/></div>} label={item.Text}/>
}
