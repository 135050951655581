import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from 'styled-components'
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import DirectionsIcon from '@mui/icons-material/Directions';
import { useOnline } from '../../../hooks/useUserInfo';
import format from 'date-fns/format'
import { AuditStatus } from '../../../util/constants';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
const useStyles = makeStyles({
  root: {
    minWidth: 275,   
  },

  title: {
    fontSize: 14,
  },

});

const BorderlessCard = styled(Card)`
   {
    border: none;
    box-shadow: none;
    min-width:275px;      
    } 
`

const CardContentInline = styled(CardContent)`
   {
    padding:8px;
    padding-top:0;
    } 
`

const CardWithGutter = styled(Card)`
   {
    margin-bottom:16px
    } 
`

const BorderlessCardWithMargin = styled(BorderlessCard)`
    margin-top:0px;
`

const RightAlignedCardActions = styled(CardActions)`
justify-content: flex-end;
`

const PositiveButton = styled(Button)`
background:green;
color:white;
`

const TextLine = styled(Typography)`
width:100%;
font-size:95%;
`

const UpdatedExternallyCard = ({audit}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleCloneFromExternallyUpdated = () => dispatch({type:"COPY_AUDIT_AND_DISCARD_ORIGINAL", payload:{auditInternalId:audit.InternalId}});

  return <CardWithGutter className={classes.root}>
      <CardContentInline>
      <Typography>Audit has be Modified</Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          This audit has been modified elsewhere and you're not using the latest copy.
        </Typography>
      
      </CardContentInline>
      <RightAlignedCardActions>
        <Button size="small" onClick={handleCloneFromExternallyUpdated}>Work on Copy</Button>
        <Button color="secondary" size="small">Discard</Button>
      </RightAlignedCardActions>
    </CardWithGutter>
}

const IsReadyCard = ({audit}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isOnline = useOnline();

  const navigateToAudit = () => {
    dispatch({type:"GO_TO_AUDIT",payload:{AuditInternalId: audit.InternalId}})
  }

  const handleCompleteAuditAndPush = ()=> {
    dispatch({type:"UPDATE_AUDIT_STATUS_AND_PUSH",payload:{InternalId: audit.InternalId, Status: AuditStatus.COMPLETE}})
  }
  const handleCompleteAudit = ()=> {
    dispatch([
        {type:"UPDATE_AUDIT_STATUS",payload:{InternalId: audit.InternalId, Status: AuditStatus.COMPLETE}},        
    ])
  }
  
  const getMessage = () => {
    switch (audit.Status) {
      case AuditStatus.READY: {
        if (isOnline) {
          return <>
          <Typography>Audit is Ready</Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
          You have completed all sections of this audit and it's ready to complete and send back.
        </Typography> </>
        }
        else {
          return  <>
          <Typography>Audit is Ready</Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
          You have completed all the sections of this audit and it's ready to complete.
        </Typography> </>
       
        }
      }
      case AuditStatus.COMPLETE: {
        if (isOnline) {
          return  <>
          <Typography>Audit is Ready</Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
          This audit has been completed and can now be sent back.
        </Typography> </>
        
        }
        else {
          return  <>
          <Typography>Audit is Ready</Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
          This audit has been completed, however cannot be sent back whilst offline. Please synchronise again when you are connected to the internet.
        </Typography> </>
        
        }
      }
      default:
        return null;
    }
  }

  const getAction = () => {
    switch (audit.Status) {
      case AuditStatus.READY: {
        if (isOnline) {
          return  <><Button  onClick={navigateToAudit} size="small">Review Audit</Button><PositiveButton onClick={handleCompleteAuditAndPush} variant="contained" size="small">Complete and Send</PositiveButton></>
        }
        else {
          return  <><Button  onClick={navigateToAudit} size="small">Review Audit</Button><PositiveButton  onClick={handleCompleteAudit} color="primary" size="small">Complete</PositiveButton></>
        }
      }
      case AuditStatus.COMPLETE: {
        if (isOnline) {
          return   <><Button  onClick={navigateToAudit} size="small">Review Audit</Button><PositiveButton  onClick={handleCompleteAuditAndPush}  color="primary" size="small">Send</PositiveButton></>
        }
        else {
          return  <><Button onClick={navigateToAudit} size="small">Review Audit</Button></>;
        }
      }
      default:
        return null;
    }
  }

  return <CardWithGutter className={classes.root}>
      <CardContentInline>        
          {getMessage()}       
      
      </CardContentInline>
      <RightAlignedCardActions>
        {getAction()}
       
      </RightAlignedCardActions>
    </CardWithGutter>
}

const AddressDetails = ({audit}) => {

  const isOnline = useOnline();
  const address = audit.Address || {};
  const addressAr = [audit.FacilityName, audit.FacilityName===audit.OrganisationName?null:audit.OrganisationName, address.AddressLine1, address.AddressLine2, address.Town, address.County, address.PostCode];
  let addressStr = "";

  return <>
  {addressAr.filter(p=> !!p).map((p,idx)=> {
    addressStr += p+"+";
    return <TextLine key={audit.Id+"address"+idx} display="block">{p}</TextLine>
  })}
 
  {false && isOnline && addressStr && <Button style={{marginTop:8}} size="small" variant="contained" endIcon={<DirectionsIcon/>} color="primary" target="_blank" href={`https://www.google.com/maps/dir/Current+Location/${addressStr}`}>Get Directions</Button>}
  </>
}

const ContactDetails = ({audit}) => {


    return <>
    <Typography color="textSecondary" gutterBottom>Contact</Typography>
    <TextLine display="block">{audit.Contact && audit.Contact.Name}</TextLine>
    <TextLine display="block">Accompanied By: {audit.AccompaniedBy || "Not Specified"}</TextLine>
  
    </>

}

const ScheduleDetails = ({audit}) => {

  const scheduled = audit.Scheduled && format(new Date(audit.Scheduled), 'dd/MM/yyyy p')
  const created = audit.CreatedTs && format(new Date(audit.CreatedTs), 'dd/MM/yyyy p')
 
    return <>

    <TextLine display="block">Scheduled {scheduled}</TextLine>
    <TextLine display="block">Created: {created}</TextLine>
    </>

}

const AuditDetailsCard = ({audit}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  return <BorderlessCardWithMargin className={classes.root}>
      <CardContentInline>
      <Grid container>
        <Grid item xs={12} sm={6}>
        <Typography className={classes.title} color="textSecondary" gutterBottom>Address</Typography>
          <AddressDetails audit={audit}/>
        </Grid>
        <Grid item xs={12} sm={6} style={isSmall?{marginTop:'16px'}:null}>
        
        {false && <ContactDetails audit={audit}/>}
        <Typography className={classes.title} style={{marginTop:8}} color="textSecondary" gutterBottom>Schedule</Typography>
        <ScheduleDetails audit={audit}/>
        </Grid>
      </Grid>
      
      </CardContentInline>
    
    </BorderlessCardWithMargin>
}

 const AuditListItemSummaryCard = ({audit}) => {
  const classes = useStyles();
  

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent style={{paddingBottom:0}}>
        {audit && audit.UpdatedExternally && <UpdatedExternallyCard audit={audit}/>}
        {audit && audit.Status >= AuditStatus.READY && !audit.UpdatedExternally && <IsReadyCard audit={audit}/>}
<AuditDetailsCard audit={audit}/>

       </CardContent>
    </Card>
  );
}

export default AuditListItemSummaryCard;
