import Config from '../config/config';

export const urlsV2 = {
    audits: {
        check: "/audits/v2/check",
        fetch: "/audits/v2/fetch",
        push: "/audits/v2/push",
       
    },
    toolkits: {
        byID: '/toolkit/instance', // /toolkit/instance/hgafdas8d98asyd9as 
        snapshots: "/audits/v2/snapshots",
    },
    status: '/status'
}

export const urls = {
    auth: {
        login: "/account/ajaxsignin"
    },
    clients: {
        getClients: "/organisation",
        getClient: "/organisation/",
        createClient: "/organisation",
        updateClient: "/organisation/",
        getFacility: "/facility/",
        createFacility: "/facility",
        updateFacility: "/facility/",
        deleteClient: "/organisation/",
        lookup:"/organisation/lookup/",
        getFacilities: "/organisation/{organisationId}/facilities"
    },
    locations: {
        createLocation: "/location",
        deleteLocation: "/location/",
        updateLocation: "/location/",
        getLocation: "/location/",
        getLocations: "/facility/"
    },
    users: {
        getUsers: "/admin/users",
        createUser: "/admin/users",
        updateUser: "/admin/users/",
        deleteUser: "/admin/users/delete/",
        forceResetPassword: "/account/ForceResetPassword",
        lookupUsers: "/admin/findusers",
        getPermissions: "/admin/users/{userId}/permissions",
        pullAudits: "/audits/pull"
    },
    toolkits: {
        search: "/toolkit/search/",
        getToolkit: "/toolkit/",
        lookupToolkits: "/toolkits/",
        createToolkit: "/toolkit",
        updateToolkit: "/toolkit/",
        deleteToolkit: "/toolkit/",
        toolkitManagement: "toolkit/manage/",
        toolkitSectionManagement: "toolkit/section/manage/",
        toolkitManager: "toolkit/manager/",
        section: "/toolkit/section",
        sections: "/toolkit/sections",
        instances: "/sections",
        createSection: "/toolkit/section",
        updateSection: "/toolkit/section/",
        getQuestions: "/toolkit/section/",
        copyQuestions : "/toolkit/section/copyquestions/",
        updateQuestions: "/toolkit/manage/",
        getToolkitInstances: "/toolkit/instances",
        getAuditPlanSummary: "/toolkit/instance/summary/",
        fetchToolkitInstance: "/toolkitinstance/",
        updateToolkitInstance: "/toolkit/instance/",
        createToolkitInstance: "/toolkitinstance/",
        cloneToolkitInstance: "/toolkitinstance/clone/",
        queryQuestions: "/toolkit/questions",
        updateQuestion: "/toolkit/question/",
        createQuestion: "/toolkit/question/",
        whereToolkitInstanceUsed: "/toolkit/instance/findwhereused",
        questionsInContext: "/toolkit/questions/context/organisation",
        queryToolkitInstances: "/toolkit/instances/query",
    },
    auditors: {
        getAuditors: "/auditors",
    },
    audits: {
        query: "/audits/query",
        editModel: "/audits/edit/",
        auditEntry: "/audits/entry/",
        newModel: "/audits/new/",
        updateAudit : "/audits/",
        deleteAudit : "/audits/",
        pushAudit: "/audits/push/",
        createAudit: "/audits/",
        updateStatus: "/audits/",
        auditEditorModel: "/audits/editor/",
    },
    reports: {
        standardAuditReport: "/reports/audits/standard/",
        auditActionPlanReport: "/reports/audits/actionplan/",
        aggregatedCorrectiveActionsReport: "/reports/agreements/aggregatedcorrectiveactions/",
        aggregatedPercentageSummaryReport: "/reports/agreements/PercentageSummary/",
        downloadStandardAuditReport: "/reports/audits/standard/download/",
        downloadAuditActionPlanReport: "/reports/audits/actionplan/download/",
        downloadBlankAuditReport: "/reports/audits/blank/download/",
        downloadBlankAuditByAgreementReport: "/reports/audits/blank/download/byagreement/",
        templateSummaryReport: "/reports/templates/summary/download/",
        agreementSummaryTemplateReport: "/reports/agreement/template/download/",
        downloadAggregatedCorrectiveActionsReport: "/reports/agreements/aggregatedcorrectiveactions/download/",
        downloadAggregatedPercentageSummaryReport: "/reports/agreements/PercentageSummary/download/",
        exportAggregatedPercentageSummaryReport: "/reports/agreements/PercentageSummary/export/",
        exportAggregatedPercentageScoresReport: "/reports/agreements/PercentageScores/download/"
    },
    standards: {
        getStandards: "/toolkit/configuration/standards",
        createStandard: "/toolkit/configuration/standards",
        updateStandard: "/toolkit/configuration/standards"
    },
    agreements: {
        getPlanner : "/agreement/planner/"
    }

};

export const apiPath = `${Config.apiRoot}/api`;