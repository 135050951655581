import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useHistory } from 'react-router-dom'
import { Badge, Box } from '@mui/material'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    flexGrow: 1
  },
  appBarSubtitle2: {
    fontSize: '0.7rem',
    lineHeight: '1'
  },
  appBarTitle: {
    marginTop: '0.3rem',
    fontSize: '1.2rem',
    lineHeight: '1.8rem'
  },
  appBarTitleComb: {
    marginTop: '-0.3rem',
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
  }
}))

export function PrimaryAppBar ({
  onDrawerChange,
  drawerOpen,
  title,
  subtitle,
  backUrl,
  goBack,
  onBack,
  disabled,
  actions
}) {
  const classes = useStyles()  
  const history = useHistory()
  const unreadAuditCount = useSelector(store=>store.audits.counts.unread)
 
  const handleToggleDrawer = () => {
    onDrawerChange && onDrawerChange(!drawerOpen)
  }

  const handleBack = async () => {
    if (backUrl) {
      history.push(backUrl)
    } else if (onBack) {
      onBack()
    } else {
      await history.goBack()
    }
  }

  const renderIcon = () => {
    if (backUrl || goBack || onBack) {
      return (
        <IconButton
          edge='start'
          onClick={handleBack}
          className={classes.menuButton}
          color='inherit'
          aria-label='menu'
          size="large">
          <ArrowBack />
        </IconButton>
      );
    }

    return (
      <IconButton
        edge='start'
        onClick={handleToggleDrawer}
        className={classes.menuButton}
        color='inherit'
        aria-label='menu'
        size="large">
        <Badge badgeContent={unreadAuditCount} color='error'>
          <MenuIcon />
        </Badge>
      </IconButton>
    );
  }

  return (
    <AppBar position='fixed'>
      <Toolbar>
        {renderIcon()}
        <Box className={classes.title}>
          <Typography
            className={subtitle ? classes.appBarTitleComb : classes.appBarTitle}
            variant='h6'
            gutterBottom={!subtitle}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography className={classes.appBarSubtitle2} variant='subtitle2'>
              {subtitle}
            </Typography>
          )}
        </Box>

        {actions}
      </Toolbar>
    </AppBar>
  )
}
