import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  toolkits: null,
  locationAgreements: null,
  facilities: null,
  isSynchronising:false
}

const snapshotReducer = createReducer(initialState, {
  'snapshots/clear': (state, action) =>  initialState,
  'snapshots/setToolkits': (state, action) =>  { state.toolkits =[...action.payload] },
  'snapshots/setLocationAgreements': (state, action) =>  { state.locationAgreements =[...action.payload] },  
  'snapshots/setFacilities': (state, action) =>  { state.facilities =[...action.payload] },  
  'snapshots/setSyncStatus': (state, action) =>  { state.isSynchronising =action.payload },  
})

export default snapshotReducer;

