import { call, put, takeLatest } from 'redux-saga/effects'
import getStorage from '../storage';


function* getQuestions(action) {
 
   try {
      const storage = getStorage();
      yield put({ type: "questions/setQuestions", payload: [] })
      const questions = yield call([storage,storage.getQuestionsForSection],action.payload);
      yield put({ type: "questions/setQuestions", payload: questions })
   } catch (e) {
      yield put({type: "questions/getQuestionsFailed", message: e});
   }
}

function* upsertAnswer (questionAnswer) {
   try {
      const storage = getStorage();
      questionAnswer = yield call([storage,storage.setAuditQuestionAnswer],questionAnswer);
      return questionAnswer;
   }
   catch(e) {
      yield put({type: "questions/upsertAnswerFailed", message: e});

   }
}


function* questionSaga() {
  yield takeLatest("GET_QUESTIONS", getQuestions);
  yield takeLatest("UPSERT_QUESTION_ANSWER", upsertAnswer);
}

export default questionSaga;