import { unstable_createMuiStrictModeTheme as createMuiTheme, adaptV4Theme } from '@mui/material/styles';
// https://stackoverflow.com/a/64135466/1850255

const themeOptions = {
  palette: {
    mode: 'light',
    background: { 
      default: '#fff'
    }    
  },
  typography: {
    fontFamily: 'Sarabun',
    h1: {
      fontFamily: 'Sarabun',
    },
    h2: {
      fontFamily: 'Sarabun',
    },
    h3: {
      fontFamily: 'Sarabun',
    },
    h4: {
      fontFamily: 'Sarabun',
    },
    h6: {
      fontFamily: 'Sarabun',
    },
    h5: {
      fontFamily: 'Sarabun',
    },
   
    button: {
      fontFamily: 'Sarabun',
      fontWeight: 900,
    },
    overline: {
      fontFamily: 'Ubuntu Mono',
    },
    subtitle1: {
      fontFamily: 'Sarabun',
    },
    subtitle2: {
      fontFamily: 'Sarabun',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fff',
        },
      },
    },
  },
};

export const lightTheme = createMuiTheme(adaptV4Theme(themeOptions));

