import React from 'react'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({


  circleText: {
    fontSize: '0.5em',
    fontWeight: 'bold',
    fill: '#999'
  },
  circleProgress: {
    fill: 'none',
    stroke: '#999',
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
  },
  circleBackground: {
    stroke: '#ddd',
    fill:'none'
  }
}))




export const CircularProgress = ({
  strokeWidth = 5,
  percentage = 25,
  sqSize = 100
}) => {
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100

  const classes = useStyles()

  return (
    <svg width={sqSize} height={sqSize} viewBox={viewBox}>
      <circle
        className={classes.circleBackground}
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className={classes.circleProgress}
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset
        }}
      />
      <text
        className={classes.circleText}
        x='50%'
        y='50%'
        dy='.3em'
        textAnchor='middle'
      >
        {`${percentage}%`}
      </text>
    </svg>
  )
}
