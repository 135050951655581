import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'
import { matches } from '../util/compare'

const initialState = {
  auditList: [],
  unopenedCount: 0,
  synchronizing: false,
  lastAudit: null,
  counts: { unread: 0, pending: 0, snapshots: 0 },
  errorState: {},
  syncAllStatus: false,
}

const auditReducer = createReducer(initialState, {
  'audits/clear': (state, action) => (state = initialState),
  'audits/setCount': (state, action) => {
    state.counts = { ...action.payload }
  },
  'audits/setAuditsForUser': (state, action) => {
    state.auditList = [...action.payload]
  },
  'audits/replaceAudit': (state, action) => {
    replaceAudit(state.auditList, action.payload)
  },
  'audits/addAuditRecords': (state, action) => {
    state.auditList.push(...action.payload)
  },
  'audits/setLastAudit': (state, action) => {
    state.lastAudit = action.payload
  },
  'audits/removeAudit': (state, action) => {
    removeAudit(state.auditList, action.payload)
  },
  'audits/addAudit': (state, action) => {
    state.auditList.push(action.payload)
  },
  'audits/setAuditSyncStatus': (state, action) => {
    state.synchronizing = action.payload
  },
  'audits/updateAudits': (state, action) => {
    updateAudits(
      state.auditList,
      action.payload.updateModel,
      action.payload.filter
    )
  },
  'audits/setToolkitSummaries': (state, action) => {
    state.toolkitSummaries = action.payload
  },
  'audits/setErrorState': (state, action) => {
    state.errorState = { ...state.errorState, ...action.payload }
  },
  'audits/syncAllStatus': (state,action) => { state.syncAllStatus = action.payload},  
})

export default auditReducer

function replaceAudit (auditList, newAudit) {
  if (!auditList) {
    return
  }
  const index = auditList.findIndex(
    audit => audit.Id === newAudit.Id && newAudit.Id
  )
  if (index === -1) {
    auditList.push(newAudit)
  } else {
    auditList[index] = newAudit
  }
}

function removeAudit (auditList, auditInternalId) {
  if (!auditList) {
    return
  }
  const index = auditList.findIndex(a => a.InternalId === auditInternalId)
  if (index === -1) {
    return
  }
  _.pullAt(auditList, index)
}

function updateAudits (auditList, updateModel, filter) {
  if (!auditList) {
    return
  }
  const indexes = []
  auditList.forEach((audit, idx) => {
    if (matches(audit, filter)) {
      indexes.push(idx)
    }
  })
  if (indexes.length === 0) {
    return 0
  }

  indexes.forEach(
    index => (auditList[index] = { ...auditList[index], ...updateModel })
  )

  return indexes.length
}
