import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useState } from 'react'
import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
  Button
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { SectionNotes } from './SectionNotes'

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}))


const NotesDialog = React.memo(
  ({ sectionId, sectionNotes, onNotesChanged, notesOpen, onClosed }) => {
    const [notes, setNotes] = useState(sectionNotes)
    const classes = useStyles()

    const handleSaveNotes = useCallback(() => {      
      onNotesChanged && onNotesChanged(sectionId, notes)
    }, [sectionId, notes, onNotesChanged])

    const handleClosed = () => {
        onClosed && onClosed();
    }

    return (
      <Dialog
        fullScreen
        open={notesOpen||false}
        onClose={handleClosed}
        //  TransitionComponent={Transition}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClosed}
              aria-label='close'
              size="large">
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' className={classes.dialogTitle}>
              Notes
            </Typography>
            <Button autoFocus color='inherit' onClick={handleSaveNotes}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <SectionNotes           
            value={notes}
            onChange={value => {
              setNotes(value)
            }}
          />
        </DialogContent>
      </Dialog>
    );
  }
)

export {NotesDialog}